import { ReactNode, FC } from "react";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { cx } from "@libs/utils/cx";
import { Layout } from "@libs/components/UI/OptionInputList";
import { FormFieldDescription } from "@libs/components/UI/FormFieldDescription";

interface Props {
  layout?: Layout;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  displayErrorMessage?: boolean;
  error?: string;
  className?: string;
  labelClassName?: string;
  listClassName?: string;
  children: JSX.Element;
  inline?: boolean;
  description?: ReactNode;
}

export const LabelledOptions: FC<Props> = ({
  className,
  label,
  error,
  displayErrorMessage = true,
  required,
  children,
  disabled,
  labelClassName,
  description,
  listClassName,
  layout = "horiz",
  inline,
}) => {
  return (
    <>
      {label ? (
        <div className={className}>
          <FormFieldLabel
            content={label}
            error={error}
            disabled={disabled}
            required={required}
            className={cx("font-sansSemiBold", labelClassName, layout === "custom" ? "" : "mb-1")}
            inline={inline}
          />
          {description ? (
            <FormFieldDescription disabled={disabled}>{description}</FormFieldDescription>
          ) : null}
          <div className={listClassName}>{children}</div>
        </div>
      ) : (
        <div className={className}>{children}</div>
      )}
      {error && displayErrorMessage ? <FormFieldError className="mt-1 mb-2">{error}</FormFieldError> : null}
    </>
  );
};
