import { GetApiMethod } from "@libs/@types/api";
import { LabCaseStatusVO } from "@libs/api/generated-api";
import { BoolVal, Enum, ParsedParams, StrVal, routesConfigHelper } from "@libs/router/url";

export type LabCasesSortField = Parameters<GetApiMethod<"practices", "getLabCases">>[1]["sortField"];
export type LabCasesSortDirection = Parameters<GetApiMethod<"practices", "getLabCases">>[1]["sortDirection"];

export const LabCasesQueryParams = {
  showArchived: BoolVal,
  patientSearchString: StrVal,
  labCaseStatuses: Enum<LabCaseStatusVO["status"]>().CSV([
    "DRAFT",
    "SENT",
    "RECEIVED",
    "RETURNED",
    "READY",
    "COMPLETE",
  ]),
  sortField: Enum<NonNullable<LabCasesSortField>>().Val([
    "DATE",
    "ESTIMATED_RECEIVE_DATE",
    "LAB",
    "NEXT_PATIENT_PROCEDURE_DATE",
    "PATIENT",
    "STATUS",
  ]),
  sortDirection: Enum<NonNullable<LabCasesSortDirection>>().Val(["ASCENDING", "DESCENDING"]),
};

export const LabCasesRoutesConfig = routesConfigHelper({
  labCases: {
    path: "/lab-cases",
    query: { ...LabCasesQueryParams },
  },
});

export type LabCasesQuery = ParsedParams<typeof LabCasesQueryParams>;
