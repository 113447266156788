import { CLINICAL_JOBS } from "@libs/api/employee/utils";
import { EmployeeVO, EmploymentDetailsVO, PracticeVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { hasValue } from "@libs/utils/validators";
import { isAdminRole } from "components/Employee/utils";

export const hasPracticeInfo = ({ contactDetails, website, physicalAddress }: PracticeVO) => {
  return [contactDetails?.phoneNumber, website, physicalAddress?.address1].every(hasValue);
};

export const hasBusinessInfo = ({ doingBusinessAs, name, businessType }: PracticeVO) => {
  return [doingBusinessAs, name, businessType].every(hasValue);
};

export const hasFilledOutPersonalInfo = (employee: EmployeeVO) => {
  const baseDetailsComplete = [
    employee.personalDetails.firstName,
    employee.personalDetails.lastName,
    employee.contactDetails.phone,
  ].every(hasValue);
  const isAdmin = isAdminRole(employee.roleV2?.name);

  return (
    baseDetailsComplete &&
    (isAdmin ? isDefined(employee.employmentDetails.jobTitleKey) : true) &&
    (employee.isOwner ? isDefined(employee.personalDetails.dob) : true)
  );
};

export const shouldFillOutLicensingInfo = (employmentDetails: EmploymentDetailsVO) => {
  return CLINICAL_JOBS.has(employmentDetails.jobTitleKey);
};

// To test new onboarding, run `localStorage.setItem("archyNewOnboarding", "1")` in the console
const TESTING_ONBOARDING_KEY = "archyNewOnboarding";

export const isTestingNewOnboarding = () => {
  return localStorage.getItem(TESTING_ONBOARDING_KEY) === "1";
};
