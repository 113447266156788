/* eslint-disable @typescript-eslint/naming-convention */
export const colors = {
  screenBackground: "#f6f8fb",
  primaryTheme: "#2057FE",
  secondaryTheme: "#153e90",
  actionLight: "#EFF3FF",
  accentLight: "#e9f1ff",
  shadow: "#94949440",
  greyDark: "#0F172A",
  greyMedium: "#334155",
  greyLight: "#64748B",
  "grey-400": "#94A3B8",
  greyLighter: "#CBD5E1",
  greyLightest: "#E2E8F0",
  whiteLight: "#F1F5F9",
  white: "#fff",
  offWhite: "#F8FAFC",
  magentaDark: "#a20759",
  magenta: "#c41974",
  magentaLight: "#f3d1e3",
  blueCadet: "#73a9ad",
  khaki: "#e6dc88",
  blueShadow: "#748da6",
  plumWeb: "#e1a1f0",
  mossGreen: "#83a04e",
  red: {
    200: "#fecaca",
    500: "#ef4444",
    800: "#991b1b",
  },
  lime: {
    200: "#d9f99d",
    500: "#84cc16",
    800: "#3f6212",
  },
  green: {
    200: "#bbf7d0",
    500: "#22c55e",
    800: "#166534",
  },
  teal: {
    200: "#99f6e4",
    500: "#14b8a6",
    800: "#115e59",
  },
  blue: {
    200: "#bfdbfe",
    500: "#3b82f6",
    800: "#1e40af",
  },
  orange: {
    200: "#fed7aa",
    500: "#f97316",
    800: "#9a3412",
  },
  yellow: {
    200: "#fef08a",
    500: "#eab308",
    800: "#854d0e",
  },
  violet: {
    200: "#ddd6fe",
    500: "#8b5cf6",
    800: "#5b21b6",
  },
  pink: {
    200: "#fbcfe8",
    500: "#ec4899",
    800: "#9d174d",
  },
  archyBlue: {
    900: "#011F7A",
    800: "#0129A2",
    700: "#0134CB",
    600: "#013DF5",
    500: "#2057FE",
    400: "#4876FE",
    300: "#7194FE",
    200: "#9AB3FE",
    100: "#C2D1FF",
    50: "#EFF3FF",
  },
  grey: {
    900: "#0F172A", // grey dark
    800: "#1E293B",
    700: "#334155", // grey medium
    600: "#475569",
    500: "#64748B", // grey light
    400: "#94A3B8",
    300: "#CBD5E1", // grey lighter
    200: "#E2E8F0", // grey lightest
    100: "#F1F5F9", // white light
    50: "#F8FAFC", // off-white
  },
  slate: {
    900: "#0F172A",
    800: "#1E293B",
    700: "#334155",
    600: "#475569",
    500: "#64748B",
    400: "#94A3B8",
    300: "#CBD5E1",
    200: "#E2E8F0",
    100: "#F1F5F9",
    50: "#F8FAFC",
  },
  perioChart: {
    bleeding: "#FF8484",
    plaque: "#9BC6FF",
    calculus: "#9FE3BC",
    suppuration: "#F9D866",
  },
  odontogram: {
    red: "#c14545",
    green: "#1d884a",
    blue: "#2f80ed",
    magenta: "#a20759",
  },
};
export const fontFamilies = {
  // List of font families to use inspired by https://ealush.com/emoji-picker-react/
  emoji: [
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Segoe UI",
    "Apple Color Emoji",
    "Twemoji Mozilla",
    "Noto Color Emoji",
    "EmojiOne Color",
    "Android Emoji",
  ],
  archyIcons: ["Archy-Icons"],
  sans: ["OpenSans-Regular", "sans-serif"],
  sansBold: ["OpenSans-Bold", "sans-serif"],
  sansBoldItalic: ["OpenSans-BoldItalic", "sans-serif"],
  sansExtraBold: ["OpenSans-ExtraBold", "sans-serif"],
  sansExtraBoldItalic: ["OpenSans-ExtraBoldItalic", "sans-serif"],
  sansItalic: ["OpenSans-Italic", "sans-serif"],
  sansLight: ["OpenSans-Light", "sans-serif"],
  sansLightItalic: ["OpenSans-LightItalic", "sans-serif"],
  sansSemiBold: ["OpenSans-SemiBold", "sans-serif"],
  sansSemiBoldItalic: ["OpenSans-SemiBoldItalic", "sans-serif"],
  dafoeRegular: ["Mr Dafoe", "sans-serif"],
};
export const fontWeight = {
  semiBold: 600,
  normal: 400,
};

// eslint-disable-next-line import/no-default-export
export default {
  colors,
  fontFamilies,
  fontWeight,
};
