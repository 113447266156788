import React, { HTMLProps } from "react";

// Alternatively, this might be populated from a config file (e.g. JSON).
export const externalLinks = {
  archyTermsOfService: "https://www.archy.com/termsofservice/",
  bulkEobProcessing: "https://support.archy.com/hc/en-us/articles/19549547152155-Bulk-Process-EOBs",
  finixTermsOfService: "https://finix-hosted-content.s3.amazonaws.com/flex/v2/finix-terms-of-service.html",
  finixDashboard: "https://archy.payments-dashboard.com/Login",
  mangoAdmin: "https://admin.mangovoice.com",
  mangoSupport: "https://mangovoice.com/help-desk/",
  emailAuthentication:
    "https://support.archy.com/hc/en-us/articles/20610633659675-Authenticate-Your-Email-Domain",
  anydeskDownloadUrl:
    "https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/FWRQVJNQBIKJ/Archy-Remote-Client.exe",
  anydeskGuide: "https://support.anydesk.com/knowledge/quick-start-guide",
};

export const getExternalLink = (name: keyof typeof externalLinks) => {
  return externalLinks[name];
};

interface Props extends HTMLProps<HTMLAnchorElement> {
  name: keyof typeof externalLinks;
}

export const ExternalLink: React.FC<Props> = ({ name, children, className, ...rest }) => {
  return (
    <a
      {...rest}
      className={className || "text-archyBlue-500 font-sansSemiBold"}
      href={getExternalLink(name)}
      target="_blank"
    >
      {children}
    </a>
  );
};
