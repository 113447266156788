export const stripAllButNumbers = (value: string) => {
  return value.replaceAll(/\D/g, "");
};

export const stripAllButEmailDomain = (value: string) => {
  return value.replaceAll(/.*@/g, "@");
};

export const stripEmailDomain = (value: string) => {
  return value.replaceAll(/\s+|@.*/g, "");
};
