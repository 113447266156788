import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const getFullUrl = (location: { pathname: string; search: string }) => {
  return `${location.pathname}${location.search}`;
};

export const getAbsoluteUrl = (origin: string, url: string) => {
  return `${origin}${url}`;
};

export const useFullUrl = () => {
  const location = useLocation();

  return useMemo(() => getFullUrl(location), [location]);
};
