/* eslint-disable @typescript-eslint/naming-convention */

import {
  BoolVal,
  DateVal,
  Enum,
  NumVal,
  ParsedParams,
  routesConfigHelper,
  StrVal,
  withDefault,
} from "@libs/router/url";
import { SerializedFilter } from "utils/routing/dashboard/serializedFilter";
import { SerializedSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { timeFormat } from "utils/routing/timeFormat";

export type DashboardQuestion =
  | "practice-production"
  | "payment-outstanding"
  | "payment-collected"
  | "patient-wallet"
  | "patients-treated"
  | "appointments-completed"
  | "appointments"
  | "adjustments"
  | "daily-report"
  | "recall"
  | "unscheduled-treatment"
  | "referrals"
  | "payroll-hours-worked";

export type AppointmentDrilldownType = "emergency" | "hygiene" | "comprehensive";
export type TimeSeriesResolutionOption = "DAY" | "WEEK" | "MONTH" | "YEAR" | "QUARTER";
export type DashboardChartDisplay = "bar" | "line";
export type PaymentOutstandingTableTab = "carriers" | "patients" | "service";
export type PracticeProductionTableTab = "providers" | "hygienists" | "appointments" | "procedures";
export type PaymentCollectedTableTab = "payment-methods" | "patients" | "providers" | "associated-provider";
export type RecallTableTab = "procedures" | "patients";
export type AdjustmentCategory = "insurance" | "collection";
export type AdjustmentTableTab = AdjustmentCategory | "provider";

export type TimeSegment = {
  startDate: Date;
  endDate: Date;
};
export const DEFAULT_RESOLUTION: TimeSeriesResolutionOption = "DAY";

const APPT_BOOKING_SORT = withDefault(SerializedSortOrder, [
  { field: "patientLastName", direction: "ASCENDING" },
  { field: "appointmentDate", direction: "ASCENDING" },
  { field: "appointmentState", direction: "ASCENDING" },
  { field: "appointmentDuration", direction: "ASCENDING" },
]);

const BaseQueryParams = {
  "table.fullScreen": BoolVal,
  filters: withDefault(SerializedFilter, []),
  patientSearch: StrVal,
  debug: withDefault(BoolVal, false),
};

const DEFAULT_DATE_INDEX = -1;

const timeResolutionParams = withDefault(
  Enum<TimeSeriesResolutionOption>().Val(["DAY", "WEEK", "MONTH", "YEAR", "QUARTER"]),
  DEFAULT_RESOLUTION
);

const BaseChartDisplayQueryParams = {
  chartDisplay: withDefault(Enum<DashboardChartDisplay>().Val(["line", "bar"]), "bar"),
};
const BaseDateNavigationQueryParams = {
  ...BaseQueryParams,
  ...BaseChartDisplayQueryParams,
  resolution: timeResolutionParams,

  // Represents the day, month, year that is clicked to focus on (the the start time of the period that the tables represent)
  focusDate: DateVal,

  // Represents the interval in time the user is viewing as a page. The window size depends on the resolution
  startDate: DateVal,
  endDate: DateVal,
};

const OutstandingCollectionsQueryParams = {
  ...BaseQueryParams,
  dateAgeIndex: withDefault(NumVal, DEFAULT_DATE_INDEX),
  ["patientTableSortV2"]: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "nextAppointmentDate", direction: "ASCENDING" },
    { field: "paymentRequestedAt", direction: "ASCENDING" },
    { field: "insuranceBalance", direction: "ASCENDING" },
    { field: "aging30OrLess", direction: "ASCENDING" },
    { field: "aging31To60", direction: "ASCENDING" },
    { field: "aging61To90", direction: "ASCENDING" },
    { field: "aging91To120", direction: "ASCENDING" },
    { field: "aging121OrMore", direction: "ASCENDING" },
    { field: "totalPatientBalance", direction: "ASCENDING" },
  ]),
  ["patientTableSort"]: withDefault(SerializedSortOrder, [
    { field: "invoiceDate", direction: "ASCENDING" },
    { field: "patientUncollectedAmount", direction: "ASCENDING" },
  ]),
  ["carrierTableSort"]: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
  ]),
  ["serviceTableSort"]: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "providerLastName", direction: "ASCENDING" },
    { field: "serviceDate", direction: "ASCENDING" },
    { field: "entryType", direction: "ASCENDING" },
    { field: "insuranceBalance", direction: "ASCENDING" },
    { field: "patientBalance", direction: "ASCENDING" },
    { field: "outstandingBalance", direction: "ASCENDING" },
    { field: "uninvoicedBalance", direction: "ASCENDING" },
    { field: "totalPatientBalance", direction: "ASCENDING" },
  ]),
};

const PaymentCollectedPathParams = {
  tableTab: Enum<PaymentCollectedTableTab>().Val([
    "payment-methods",
    "patients",
    "providers",
    "associated-provider",
  ]),
};
const PaymentCollectedQueryParams = {
  ...BaseDateNavigationQueryParams,
  ["patientTableSort"]: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
  ]),
  ["providerTableSort"]: withDefault(SerializedSortOrder, [
    { field: "providerLastName", direction: "ASCENDING" },
    { field: "patientLastName", direction: "ASCENDING" },
    {
      field: "associatedProviderLastName",
      direction: "ASCENDING",
    },
  ]),
};

const PatientWalletQueryParams = {
  patientSearch: StrVal,
  tableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    {
      field: "walletBalance",
      direction: "ASCENDING",
    },
    {
      field: "patientBalance",
      direction: "ASCENDING",
    },
    {
      field: "lastActivityDate",
      direction: "ASCENDING",
    },
  ]),
};
const PayrollHoursQueryParams = {
  ...BaseDateNavigationQueryParams,
  timeFormat,
  ["employeeTableSort"]: withDefault(SerializedSortOrder, [
    { field: "providerLastName", direction: "ASCENDING" },
    {
      field: "secondsWorked",
      direction: "ASCENDING",
    },
    {
      field: "status",
      direction: "ASCENDING",
    },
  ]),
};

const PracticeProductionQueryParams = {
  ...BaseDateNavigationQueryParams,
  apptTableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "productionDate", direction: "ASCENDING" },
    { field: "providerLastName", direction: "ASCENDING" },
  ]),
  procedureTableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "productionDate", direction: "ASCENDING" },
    { field: "providerLastName", direction: "ASCENDING" },
    { field: "dentalProcedureCdtCode", direction: "ASCENDING" },
    { field: "dentalProcedureSimpleName", direction: "ASCENDING" },
  ]),
};

const PatientsTreatedQueryParams = {
  ...BaseDateNavigationQueryParams,

  tableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "patientDob", direction: "ASCENDING" },
    { field: "primaryInsuranceCarrierName", direction: "ASCENDING" },
    { field: "patientType", direction: "ASCENDING" },
  ]),
};

const AppointmentsCompletedParams = {
  ...BaseDateNavigationQueryParams,

  tableSort: APPT_BOOKING_SORT,
};

const TabPathParams = {
  dashboardQuestion: Enum<DashboardQuestion>().Val([
    "practice-production",
    "payment-outstanding",
    "payment-collected",
    "patient-wallet",
    "patients-treated",
    "appointments-completed",
    "appointments",
    "adjustments",
    "daily-report",
    "recall",
    "unscheduled-treatment",
    "referrals",
    "payroll-hours-worked",
  ]),
};

export const PracticeProductionTabParams = {
  tableTab: Enum<PracticeProductionTableTab>().Val(["procedures", "providers", "appointments", "hygienists"]),
};

export const OutstandingCollectionsTabParams = {
  tableTab: Enum<PaymentOutstandingTableTab>().Val(["carriers", "patients", "service"]),
};

export const AdjustmentsQueryParams = {
  ...BaseDateNavigationQueryParams,
  collectionTableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "adjustmentDate", direction: "ASCENDING" },
    { field: "customAdjustmentTypeId", direction: "ASCENDING" },
    { field: "collectionAdjustmentAmount", direction: "ASCENDING" },
  ]),
  insuranceTableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "serviceDate", direction: "ASCENDING" },
    { field: "insuranceOrdinal", direction: "ASCENDING" },
    { field: "insuranceAdjustmentAmount", direction: "ASCENDING" },
  ]),
  providerTableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "customAdjustmentTypeId", direction: "ASCENDING" },
    { field: "insuranceOrdinal", direction: "ASCENDING" },
  ]),
};

export const AdjustmentTabParams = {
  tableTab: Enum<AdjustmentTableTab>().Val(["insurance", "collection", "provider"]),
};

const AppointmentDrilldownParams = {
  apptType: Enum<AppointmentDrilldownType>().Val(["emergency", "comprehensive", "hygiene"]),
};

const RECALL_REPORT_SORT = withDefault(SerializedSortOrder, [
  { field: "patientLastName", direction: "ASCENDING" },
  { field: "scheduledDate", direction: "ASCENDING" },
  { field: "dueDate", direction: "ASCENDING" },
  { field: "previousDate", direction: "ASCENDING" },
  { field: "previousAppointmentDate", direction: "ASCENDING" },
]);

const RecallQueryParams = {
  ...BaseQueryParams,
  dateAgeIndex: withDefault(NumVal, 0),
  procedureTableSort: RECALL_REPORT_SORT,
  patientTableSort: RECALL_REPORT_SORT,
};

const UnscheduledTreatmentQueryParams = {
  ...BaseQueryParams,
  dateAgeIndex: withDefault(NumVal, 0),
  tableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "priority", direction: "ASCENDING" },
    { field: "providerLastName", direction: "ASCENDING" },
    { field: "dentalProcedureCdtCode", direction: "ASCENDING" },
  ]),
};

export const RecallTabParams = {
  tableTab: Enum<RecallTableTab>().Val(["procedures", "patients"]),
};

export const AppointmentDrilldownQueryParms = {
  ...BaseDateNavigationQueryParams,
  tableSort: APPT_BOOKING_SORT,
};

const DailyReportQueryParams = {
  ...BaseQueryParams,
  focusDate: StrVal,
  tableSort: withDefault(SerializedSortOrder, [{ field: "patientLastName", direction: "ASCENDING" }]),
};

const ReferralsQueryParams = {
  ...BaseDateNavigationQueryParams,
  tableSort: withDefault(SerializedSortOrder, [
    { field: "patientLastName", direction: "ASCENDING" },
    { field: "insuranceCarrierId", direction: "ASCENDING" },
    { field: "referredBy", direction: "ASCENDING" },
    { field: "firstAppointmentDate", direction: "ASCENDING" },
  ]),
};

export const DashboardRoutesConfig = routesConfigHelper({
  dashboardTab: {
    path: "/dashboard/:dashboardQuestion",
    params: TabPathParams,
  },
  dashboardPracticeProduction: {
    path: "/dashboard/practice-production/:tableTab",
    query: PracticeProductionQueryParams,
    params: PracticeProductionTabParams,
  },
  dashboardPaymentOutstanding: {
    path: "/dashboard/payment-outstanding/:tableTab",
    query: OutstandingCollectionsQueryParams,
    params: OutstandingCollectionsTabParams,
  },
  dashboardPaymentCollected: {
    path: "/dashboard/payment-collected/:tableTab",
    params: PaymentCollectedPathParams,
    query: PaymentCollectedQueryParams,
  },
  dashboardPatientWallet: {
    path: "/dashboard/patient-wallet",
    query: PatientWalletQueryParams,
  },
  dashboardPatientsTreated: {
    path: "/dashboard/patients-treated",
    query: PatientsTreatedQueryParams,
  },
  dashboardAppointmentsCompleted: {
    path: "/dashboard/appointments-completed",
    query: AppointmentsCompletedParams,
  },
  dashboardAppointmentDrilldown: {
    path: "/dashboard/appointments/:apptType",
    params: AppointmentDrilldownParams,
    query: AppointmentDrilldownQueryParms,
  },
  dashboardAdjustments: {
    path: "/dashboard/adjustments/:tableTab",
    query: AdjustmentsQueryParams,
    params: AdjustmentTabParams,
  },
  dashboardDailyReport: {
    path: "/dashboard/daily-report",
    query: DailyReportQueryParams,
  },
  dashboardPayrollHours: {
    path: "/dashboard/payroll/hours",
    query: PayrollHoursQueryParams,
  },
  dashboardRecall: {
    path: "/dashboard/recall/:tableTab",
    query: RecallQueryParams,
    params: RecallTabParams,
  },
  dashboardUnscheduledTreatment: {
    path: "/dashboard/unscheduled-treatment",
    query: UnscheduledTreatmentQueryParams,
  },
  dashboardReferrals: {
    path: "/dashboard/referrals",
    query: ReferralsQueryParams,
  },
});
export type DashboardQuery = ParsedParams<typeof BaseQueryParams>;
export type DashboardQueryWithChartDisplay = ParsedParams<typeof BaseChartDisplayQueryParams>;

export type DashboardQueryWithDateNavigation = ParsedParams<typeof BaseDateNavigationQueryParams>;
export type PracticeProductionQuery = ParsedParams<typeof PracticeProductionQueryParams>;
export type OutstandingCollectionsQuery = ParsedParams<typeof OutstandingCollectionsQueryParams>;
export type PaymentCollectedQuery = ParsedParams<typeof PaymentCollectedQueryParams>;
export type PatientWalletQuery = ParsedParams<typeof PatientWalletQueryParams>;
export type PatientsTreatedQuery = ParsedParams<typeof PatientsTreatedQueryParams>;
export type AppointmentsCompletedQuery = ParsedParams<typeof AppointmentsCompletedParams>;
export type DashboardAdjustmentsQuery = ParsedParams<typeof AdjustmentsQueryParams>;
export type DailyReportQuery = ParsedParams<typeof DailyReportQueryParams>;
export type RecallReportQuery = ParsedParams<typeof RecallQueryParams>;
export type UnscheduledTreatmentQuery = ParsedParams<typeof UnscheduledTreatmentQueryParams>;
export type ReferralsQuery = ParsedParams<typeof ReferralsQueryParams>;
export type PayrollHoursQuery = ParsedParams<typeof PayrollHoursQueryParams>;
