import { useCallback } from "react";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { exactLength } from "@libs/utils/validators";
import { useObjectState } from "@libs/hooks/useObjectState";

const OTP_LENGTH = 6;
const schema = {
  otp: [{ $v: exactLength(OTP_LENGTH), $error: `Please complete the code before submitting` }],
};

type MFAEntryState = {
  otp: string;
  errorMessage?: string;
};
export const useMfaChallenge = () => {
  const [mfaEntryState, updateMfaEntryState] = useObjectState<MFAEntryState>({
    otp: "",
    errorMessage: undefined,
  });
  const rememberDevice = useBoolean(false);
  const validation = useValidation(
    {
      otp: mfaEntryState.otp,
    },
    schema
  );

  return {
    mfaEntryState,
    updateMfaEntryState,
    handleOtpChange: useCallback(
      (otp: string) => updateMfaEntryState({ otp, errorMessage: undefined }),
      [updateMfaEntryState]
    ),
    rememberDevice,
    validation,
  };
};
