import { createContext, useContext } from "react";
import { RoleVO, NameVO, EmployeeVO } from "@libs/api/generated-api";

type BaseUser = {
  id: number;
  roleV2: RoleVO;
  name: NameVO;
  phone: string | undefined;
  email: string;
};

export type EmployeeUser = {
  type: "EMPLOYEE";
  employmentDetails: EmployeeVO["employmentDetails"];
  employeeSettings: EmployeeVO["employeeSettings"];
} & BaseUser;

export type SupportUser = {
  type: "SUPPORT_USER";
} & BaseUser;

export type CurrentUser = EmployeeUser | SupportUser;

const Context = createContext({} as CurrentUser);

Context.displayName = "CurrentUser";
export const CurrentUserContext = Context;
export const useCurrentUser = () => useContext(CurrentUserContext);
