import { FC } from "react";
import { ReactComponent as SecureLockAndKeyImg } from "assets/components/restricted-content.svg";

export const AccessDeniedContent: FC<{ reason?: string }> = ({ reason }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <SecureLockAndKeyImg />
      <div className="text-sm">Access to this content is restricted.</div>
      {reason && <div className="text-sm">{reason}</div>}
    </div>
  );
};
