import { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  UseInfiniteApiListQueryResult,
  UseInfiniteApiQueryResult,
  BaseApiEntryResult,
} from "@libs/@types/apiQueries";
import { getInfiniteScrollOptionsFromQuery } from "@libs/utils/queries";

export interface UseInfiniteScrollQueryProps {
  infiniteQuery: UseInfiniteApiListQueryResult | UseInfiniteApiQueryResult<BaseApiEntryResult>;

  // See documentation on https://www.npmjs.com/package/react-infinite-scroll-hook.
  rootMargin?: string;
}

export const useInfiniteScrollQuery = ({
  infiniteQuery,
  rootMargin = "0px 0px 200px 0px",
}: UseInfiniteScrollQueryProps) => {
  const options = useMemo(() => {
    return {
      ...getInfiniteScrollOptionsFromQuery<unknown[] | BaseApiEntryResult>(infiniteQuery),
      rootMargin,
    };
  }, [infiniteQuery, rootMargin]);
  const [scrollRef, { rootRef }] = useInfiniteScroll(options);

  return {
    rootRef,
    scrollRef,
  };
};
