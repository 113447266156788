import { useEffect } from "react";

type KeyboardEventCallback = (e: KeyboardEvent) => void;

export const useKeyEventCallback = (
  event: "keypress" | "keydown" | "keyup",
  key: string,
  onKeyEvent?: KeyboardEventCallback
) => {
  useEffect(() => {
    let handleKeypress: undefined | KeyboardEventCallback = undefined;

    if (onKeyEvent) {
      handleKeypress = (e: KeyboardEvent) => {
        if (e.key === key) {
          onKeyEvent(e);
        }
      };
      document.addEventListener(event, handleKeypress);
    }

    return () => {
      if (handleKeypress) {
        document.removeEventListener(event, handleKeypress);
      }
    };
  }, [event, key, onKeyEvent]);
};
