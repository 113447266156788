const domainRegex =
  /^(xn--)?[\da-z][\d_a-z-]{0,61}[\da-z]?\.(xn--)?([\da-z-]{1,61}|[\da-z-]{1,30}\.[a-z]{2,})$/;

export const isDomain = (value: string) => {
  if (value.startsWith("-")) {
    return false;
  }

  return domainRegex.test(value);
};
