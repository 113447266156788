import { EmploymentDetailsVO } from "@libs/api/generated-api";

// When creating employees, can set `roleV2Id` to `EMPLOYEE_ADMIN_ROLE` to give them admin access
export const EMPLOYEE_ADMIN_ROLE = 0;

// List of clinical jobs: https://www.notion.so/archydental/Onboarding-Updates-2024-8f3377d5ee61437289c1b3dd5a6f1552?pvs=4#3ffce2c846eb4cb5976bbdb6772cd088
export const CLINICAL_JOBS = new Set<EmploymentDetailsVO["jobTitleKey"]>([
  "GENERAL_DENTIST",
  "PEDIATRIC_DENTIST",
  "ENDODONTIST",
  "ORTHODONTIST",
  "ORAL_SURGEON",
  "PERIODONTIST",
  "PROSTHODONTIST",
]);
