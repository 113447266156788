import { CreateClinicianRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const fetchPatientDosespotSingleSignOnUri = makeMutation({
  mutationKey: ["practices", "fetchPatientDosespotSingleSignOnUri"],
  formatParams: (args: { practiceId: number; patientId: number }) => [args.practiceId, args.patientId],
});

export const fetchDosespotSingleSignOnUri = makeMutation({
  mutationKey: ["practices", "fetchDosespotSingleSignOnUri"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
});

export const createClinician = makeMutation({
  mutationKey: ["practices", "createClinician"],
  formatParams: (args: { practiceId: number; employeeId: number; data: CreateClinicianRequest }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, employeeId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getClinicianPrescriptionStatus"),
        { practiceId, employeeId },
      ]);
    },
  }),
});
