import { NumVal, routesConfigHelper } from "@libs/router/url";

const MessagingQueryParams = {
  patientId: NumVal,
};

export const MessagingRoutesConfig = routesConfigHelper({
  messaging: {
    path: "/messaging",
    query: MessagingQueryParams,
  },
});
