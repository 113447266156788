export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const BAD_REQUEST = 400;
export const REQUEST_TIMEOUT = 408;
export const CONFLICT = 409;
export const FORBIDDEN = 403;
export const TOO_EARLY = 425;
export const TOO_MANY_REQUESTS = 429;
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const SERVICE_UNAVAILABLE = 503;
export const GATEWAY_TIMEOUT = 504;
export const SUCCESS = 200;

export const retryCodes = new Set([BAD_GATEWAY, SERVICE_UNAVAILABLE]);
