export const RECORDS_PER_PAGE = 25;

/**
 * Converts a number to its ordinal numeral form.
 *
 * `1` -> `"1st"`, `2` -> `"2nd"`, `3` -> `"3rd"`, `4` -> `"4th"`, etc.
 *
 * @param num
 * @returns
 */
export const numberToNth = (num: number) => {
  // source: https://stackoverflow.com/a/13627586
  const j = num % 10,
    k = num % 100;

  if (j === 1 && k !== 11) {
    return `${num}st`;
  }

  if (j === 2 && k !== 12) {
    return `${num}nd`;
  }

  if (j === 3 && k !== 13) {
    return `${num}rd`;
  }

  return `${num}th`;
};
