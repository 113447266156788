import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export type ModalTextAlign = "text-left" | "text-right" | "text-center";
export interface AlertModalProps extends Pick<ModalProps, "centerVertically" | "size"> {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  confirmText?: ReactNode;
  onConfirm: Func;
  isConfirming?: boolean;
  onCancel?: Func;
  cancelText?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  textAlign?: ModalTextAlign;
  childrenPlacement?: "BEFORE_TEXT" | "AFTER_TEXT";
}

export const Content: FC<{
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  textAlign?: ModalTextAlign;
}> = ({ primaryText, secondaryText, textAlign = "text-center" }) => (
  <div className="flex flex-col gap-y-2">
    <div className={cx("font-sansSemiBold text-sm", textAlign)}>{primaryText}</div>
    {secondaryText && <div className={cx("font-sans text-xs", textAlign)}>{secondaryText}</div>}
  </div>
);

export const AlertModal: FC<AlertModalProps> = ({
  size = "xs",
  primaryText,
  secondaryText,
  onConfirm,
  children,
  centerVertically = false,
  isConfirming = false,
  disabled = false,
  onCancel,
  cancelText = "Cancel",
  confirmText = "OK",
  textAlign = "text-center",
  childrenPlacement,
}) => {
  return (
    <Modal centerVertically={centerVertically} size={size}>
      <ModalContent className="pt-8 px-10 pb-6">
        {childrenPlacement === "BEFORE_TEXT" && children}
        <Content primaryText={primaryText} secondaryText={secondaryText} textAlign={textAlign} />
        {childrenPlacement !== "BEFORE_TEXT" && children}
      </ModalContent>
      <ModalFooter className="border-none" paddingClassName="pb-8">
        {onCancel && (
          <Button onClick={onCancel} theme="secondary" className="min-w-button">
            {cancelText}
          </Button>
        )}
        <AsyncButton
          className="min-w-button"
          onClick={onConfirm}
          isLoading={isConfirming}
          disabled={disabled}
        >
          {confirmText}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
