import { useMemo } from "react";

export const pick = <O, K extends StringKeys<O>[]>(obj: O, keys: K) => {
  const values = keys.map((key) => obj[key]);

  const copy: Record<string, unknown> = {};

  for (const [index, key] of keys.entries()) {
    copy[key] = values[index];
  }

  return copy as Pick<O, ListItem<K>>;
};

export const usePick = <O, K extends StringKeys<O>[]>(obj: O, keys: K) => {
  const values = keys.map((key) => obj[key]);

  // Since all we care about are the values themselves we can disable this rule here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => pick(obj, keys), values);
};
