import { SortOrder } from "@libs/api/generated-api";

import { paramsError, parseEnum } from "@libs/router/url";

const SORT_FIELDS = [
  "patientLastName",
  "providerLastName",
  "associatedProviderLastName",
  "productionDate",
  "appointmentDate",
  "invoiceDate",
  "patientUncollectedAmount",
  "dentalProcedureCdtCode",
  "dentalProcedureSimpleName",
  "patientDob",
  "primaryInsuranceCarrierName",
  "patientType",
  "appointmentDuration",
  "appointmentState",
  "insuranceAdjustmentAmount",
  "collectionAdjustmentAmount",
  "customAdjustmentTypeId",
  "adjustmentDate",
  "serviceDate",
  "insuranceOrdinal",
  "eventType",
  "scheduledDate",
  "dueDate",
  "previousDate",
  "previousAppointmentDate",
  "priority",
  "insuranceCarrierId",
  "referredBy",
  "firstAppointmentDate",

  // Uncollected by Service:
  "entryType",
  "insuranceBalance",
  "patientBalance",
  "outstandingBalance",
  "uninvoicedBalance",
  "invoicedBalance",
  "totalPatientBalance",

  // Uncollected by patient aging
  "nextAppointmentDate",
  "paymentRequestedAt",
  "aging30OrLess",
  "aging31To60",
  "aging61To90",
  "aging91To120",
  "aging121OrMore",

  // Payroll table
  "secondsWorked",
  "status",

  // patient wallet
  "walletBalance",
  "lastActivityDate",
];
const SERIALIZED_DIRECTION_VALUES = new Set(["ASC", "DESC"]);

export type ColumnSortOrder = {
  direction: SortOrder["direction"];
  field: (typeof SORT_FIELDS)[number];
};

// Sort values are structured as "field-direction,"
const ARRAY_DELIMITER = "-";
const VALUE_DELIMITER = ";";

export const SerializedSortOrder = {
  get: (value: string) => {
    const fieldValues = new Set(SORT_FIELDS);

    try {
      const parts = value.split(ARRAY_DELIMITER);

      return parts.map((part) => {
        const [field, direction] = part.split(VALUE_DELIMITER);
        const directionValue = parseEnum(SERIALIZED_DIRECTION_VALUES, direction);

        return {
          field: parseEnum(fieldValues, field),
          direction: directionValue === "ASC" ? "ASCENDING" : "DESCENDING",
        };
      }) as ColumnSortOrder[];
    } catch {
      throw paramsError(`Error parsing sort order value: ${value}`);
    }
  },
  set: (val: ColumnSortOrder[]) => {
    return val
      .map(({ field, direction }) => {
        return `${field}${VALUE_DELIMITER}${direction === "ASCENDING" ? "ASC" : "DESC"}`;
      })
      .join(ARRAY_DELIMITER);
  },
};
