import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

interface Props {
  disabled?: boolean;
}

export const FormFieldDescription: FC<PropsWithChildren<Props>> = ({ children, disabled }) => {
  return <div className={cx("text-xs pb-2", disabled ? "text-slate-400" : "text-slate-700")}>{children}</div>;
};
