import { Enum } from "@libs/router/url";

export type SignOutReason =
  | "ARCHIVED"
  | "INACTIVE"
  | "FURLOUGHED"
  | "UNAUTHORIZED"
  | "IP_BLOCKED"
  | "MFA_ERROR"
  | "SESSION_EXPIRED"
  | "NO_CURRENT_USER"
  | "IDENTITY_EMAIL_NOT_FOUND"
  | "GET_USER_ACCOUNTS_ERROR"
  | "NO_USER_ACCOUNTS_ERROR"
  | "ISSUE_TOKEN_ERROR"
  | "GET_USER_MFA_ERROR"
  | "GET_EMPLOYEE_ERROR"
  | "GET_PRACTICE_ERROR";

export const SignOutReasonVal = Enum<SignOutReason>().Val([
  "ARCHIVED",
  "UNAUTHORIZED",
  "INACTIVE",
  "FURLOUGHED",
  "MFA_ERROR",
  "IP_BLOCKED",
  "SESSION_EXPIRED",
  "IDENTITY_EMAIL_NOT_FOUND",
  "NO_CURRENT_USER",
  "GET_USER_ACCOUNTS_ERROR",
  "NO_USER_ACCOUNTS_ERROR",
  "ISSUE_TOKEN_ERROR",
  "GET_USER_MFA_ERROR",
  "GET_EMPLOYEE_ERROR",
  "GET_PRACTICE_ERROR",
]);

const accountErrorMessage = "Sorry, there was an error accessing your account.";
const deactivatedErrorMessage = "The account you signed in to has been deactivated.";

export const SignInErrorMessages: Record<SignOutReason | "INVALID_CREDENTIALS", string> = {
  INVALID_CREDENTIALS: "Invalid username or password.",
  UNAUTHORIZED: "Sorry, there was an error accessing your account. Please contact your administrator.",
  ARCHIVED: deactivatedErrorMessage,
  INACTIVE: deactivatedErrorMessage,
  FURLOUGHED: deactivatedErrorMessage,
  GET_USER_MFA_ERROR: accountErrorMessage,
  IP_BLOCKED: "Your account can't be accessed from your current location. Please contact your administrator.",
  MFA_ERROR: accountErrorMessage,
  SESSION_EXPIRED: "Session expired. Please sign in again.",
  IDENTITY_EMAIL_NOT_FOUND: accountErrorMessage,
  NO_CURRENT_USER: "",
  GET_EMPLOYEE_ERROR: accountErrorMessage,
  GET_PRACTICE_ERROR: accountErrorMessage,
  GET_USER_ACCOUNTS_ERROR: accountErrorMessage,
  NO_USER_ACCOUNTS_ERROR: accountErrorMessage,
  ISSUE_TOKEN_ERROR: accountErrorMessage,
};
