import type { PermissionActionVO } from "@libs/api/generated-api";
import type { RouteIds } from "components/PatientProfile/types";
import { PermissionAction } from "components/Roles/constants";

export const patientRoutePermissions: Record<
  RouteIds,
  {
    domain: PermissionActionVO["domain"];
    action: PermissionAction;
  }
> = {
  overview: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  edit: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  ["medical-history"]: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  insurance: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  claims: {
    domain: "CLAIMS",
    action: "ACCESS_ALL",
  },
  ["lab-cases"]: {
    domain: "LAB_CASE",
    action: "ACCESS_ALL",
  },
  charting: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  imaging: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  documents: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  forms: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  billing: {
    domain: "BILLING",
    action: "ACCESS_ALL",
  },
  communication: {
    domain: "COMMUNICATION",
    action: "ACCESS_ALL",
  },
  erx: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
  perioCompare: {
    domain: "PATIENT_PROFILE",
    action: "ACCESS_ALL",
  },
};
