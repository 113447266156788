export enum StorageNamespaces {
  accounts = "accounts",
  scheduling = "scheduling",
  scannerSettings = "scannerSettings",
  officeChat = "officeChat",
  mango = "mango",
  billing = "billing",
  imagingDevices = "imaging", // not ideal name, but not worth migrating
  imageEditor = "imageEditor",
  postSignOut = "postSignOut",
  patientAlertSnooze = "patientAlertSnooze",
}
