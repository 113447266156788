import { cx } from "@libs/utils/cx";

const cxSizes = {
  panel: "w-20 h-20",
  sm: "w-4 h-4",
  md: "w-5 h-5",
  xs: "w-3 h-3",
};

const cxVariants = {
  primary: "text-primaryTheme",
  secondary: "text-greyMedium",
  greyLighter: "text-greyLighter",
  light: "text-white",
};

type Size = keyof typeof cxSizes;

const cxAnimations = {
  border: (size?: Size) =>
    cx(
      "animate-spinnerBorder border-current border-r-transparent",
      size === "sm" ? "border-[0.2em]" : "border-[0.25em]"
    ),
  grow: () => "animate-spinnerGrow opacity-0 bg-current",
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: Size;
  animation: keyof typeof cxAnimations;
  variant?: keyof typeof cxVariants;
  className?: string;
  layout?: "inline-block" | "block";
}

const cxDefaults = {
  size: "w-8 h-8",
  variant: "text-white",
};

export const Spinner: React.FC<Props> = ({
  size,
  variant,
  animation,
  className,
  layout = "inline-block",
  ...props
}) => {
  return (
    <div
      role="img"
      aria-label="Loading"
      {...props}
      className={cx(
        "rounded-full",
        layout,
        cxAnimations[animation](size),
        size ? cxSizes[size] : cxDefaults.size,
        variant ? cxVariants[variant] : cxDefaults.variant,
        className
      )}
    />
  );
};
