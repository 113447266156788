import React from "react";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

type Props = {
  children: React.ReactNode;
  filters: DashboardFilter[];
  patientSearch?: string;
};

export const DashboardTableFilters: React.FC<Props> = ({ children, patientSearch, filters }) => {
  if (filters.length === 0 && !patientSearch) {
    return null;
  }

  return <div className="flex border-t border-greyLighter bg-white p-3">{children}</div>;
};
