import { useMemo } from "react";

import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { StorageNamespaces } from "storage/namespaces";

export type StoredAccountV1 = {
  fullName?: string;
  email: string;
  profilePic: string;
};

const accountsVersion = "v1";
const accountsKey = "savedAccounts";
const MAX_ACCOUNTS = 5;

export const getAccountsStorage = (storage: Storage) => {
  const accountsStorage = wrapStorage<StoredAccountV1[], StorageNamespaces>(
    StorageNamespaces.accounts,
    storage
  );

  const getSavedAccounts = () => {
    return accountsStorage.get(accountsKey, accountsVersion, { skipExpires: true }) || [];
  };

  // if doesn't exist append to beginning
  // if does exist, move to beginning
  const addAccount = (account: StoredAccountV1) => {
    const savedAccounts = getSavedAccounts();

    const matchedIndex = savedAccounts.findIndex((acc) => acc.email === account.email);

    const newAccounts =
      matchedIndex >= 0
        ? [account, ...savedAccounts.filter((_, index) => index !== matchedIndex)]
        : [account, ...savedAccounts];

    accountsStorage.set(accountsKey, newAccounts.slice(0, MAX_ACCOUNTS), {
      version: accountsVersion,
    });
  };
  const removeAccount = (email: string) => {
    const newAccounts = getSavedAccounts().filter((acc) => acc.email !== email);

    if (newAccounts.length) {
      accountsStorage.set(accountsKey, newAccounts, {
        version: accountsVersion,
      });
    } else {
      accountsStorage.clear(accountsKey);
    }

    return newAccounts;
  };

  return {
    getSavedAccounts,
    addAccount,
    removeAccount,
  };
};

export const useAccountsStorage = () => {
  const { localStorage } = useStorageContext();

  return useMemo(() => getAccountsStorage(localStorage), [localStorage]);
};
