import { FC } from "react";
import LightSkeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { DarkRoomSkeleton } from "@libs/components/UI/DarkRoomSkeleton";

export const LoadingContent: FC<{
  containerTestId?: string;
  containerClassName?: string;
  className?: string;
  isDark?: boolean;
}> = ({ containerClassName, containerTestId, className, isDark = false }) => {
  const Skeleton = isDark ? DarkRoomSkeleton : LightSkeleton;

  return (
    <Skeleton
      containerTestId={containerTestId ?? "loading-content"}
      containerClassName={cx("h-full w-full min-h-[inherit] block", containerClassName)}
      inline={true}
      className={cx("h-full w-full min-h-[inherit] block", className)}
    />
  );
};
