const MIN_LENGTH = 8;
const MAX_LENGTH = 99;

export const isValidPassword = (value: string) => {
  if (value.length < MIN_LENGTH || value.length > MAX_LENGTH) {
    return false;
  }

  if (!/[a-z]/.test(value)) {
    return false;
  }

  if (!/[A-Z]/.test(value)) {
    return false;
  }

  if (!/\d/.test(value)) {
    return false;
  }

  if (!/[!#$%&()*,./:;<>?@[\]^_`{|}~’“-]/.test(value)) {
    return false;
  }

  if (/\s/.test(value)) {
    return false;
  }

  return true;
};
