type SetValues = string | number | boolean;
export const toggleSet = <V extends SetValues, S extends Set<V>>(currentSet: S, value: V): S => {
  const next = new Set(currentSet) as S;

  if (next.has(value)) {
    next.delete(value);
  } else {
    next.add(value);
  }

  return next;
};
