import { createContext, useContext, FC, ReactNode } from "react";
import { getMemoryStorage } from "@libs/storage/getStorage";

export type LocalAndSessionStorage = { localStorage: Storage; sessionStorage: Storage };

const Context = createContext<LocalAndSessionStorage>({
  localStorage: getMemoryStorage(),
  sessionStorage: getMemoryStorage(),
});

Context.displayName = "StorageContext";

export const useStorageContext = () => useContext(Context);

export const StorageContextProvider: FC<{ children?: ReactNode; value: LocalAndSessionStorage }> = ({
  children,
  value,
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
