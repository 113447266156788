import { FC, ReactNode } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Modal } from "@libs/components/UI/Modal";
import { AlertModalProps, Content, ModalTextAlign } from "@libs/components/UI/AlertModal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export interface ConfirmationModalProps extends AlertModalProps {
  onCancel: Func;
  isCancelling?: boolean;
  cancelText?: ReactNode;
  textAlign?: ModalTextAlign;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  size = "xs",
  primaryText,
  secondaryText,
  confirmText,
  cancelText,
  onCancel,
  onConfirm,
  children,
  centerVertically = false,
  isConfirming = false,
  isCancelling = false,
  disabled = false,
  textAlign = "text-center",
}) => {
  return (
    <Modal centerVertically={centerVertically} size={size}>
      <ModalContent className="pt-8 px-10 pb-6">
        <Content primaryText={primaryText} secondaryText={secondaryText} textAlign={textAlign} />
        {children}
      </ModalContent>
      <ModalFooter className="border-none" paddingClassName="pb-8">
        <AsyncButton
          isLoading={isCancelling}
          className="min-w-button"
          onClick={onCancel}
          disabled={disabled}
          theme="secondary"
          size="medium"
        >
          {cancelText ?? "Cancel"}
        </AsyncButton>
        <AsyncButton
          className="min-w-button"
          onClick={onConfirm}
          isLoading={isConfirming}
          disabled={disabled}
          size="medium"
        >
          {confirmText ?? "Confirm"}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
