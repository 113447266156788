import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { paramsError } from "@libs/router/url";

const MAX_DATE_LENGTH = 2;
const dateRegex = /\d{4}-\d{2}-\d{2}/;

const isValidRangeItem = (val: string) =>
  val === "" || (dateRegex.test(val) && !Number.isNaN(Date.parse(val)));

/*
Allows you to store a date range as a query param

e.g.
?dateRange=2022-12-24,2022-12-25 => { dateRange: [Date, Date] }
?dateRange=2022-12-24,           => { dateRange: [Date, null] }
?dateRange=,2022-12-25           => { dateRange: [null, Date] }
*/

export const DateRangeVal = {
  get: (val: string): [Date | null, Date | null] => {
    const dates = val.split(",");

    if (
      // no empty valu
      !val ||
      // ranges are max two dates
      dates.length !== MAX_DATE_LENGTH ||
      // dates must be empty string or YYYY-MM-DD
      dates.some((date) => !isValidRangeItem(date)) ||
      // dates cant be all empty strings
      dates.every((date) => date === "")
    ) {
      throw paramsError("The date range could not be parsed");
    }

    return dates.map((date) => (date === "" ? null : getLocalDate(date))) as [Date | null, Date | null];
  },
  set: (val?: [Date | null, Date | null]) => {
    if (val === undefined || val.every((valItem) => valItem === null)) {
      return "";
    }

    return val.map((valItem) => (valItem === null ? "" : formatAsISODate(valItem))).join(",");
  },
};
