import type { ApiMethodData, ApiName, ApiResponse, Domains, GetApiMethod } from "@libs/@types/api";
import type {
  ApiQueryResult,
  MakeInfiniteQuery,
  MakeOptionalArgsQuery,
  MakeQuery,
  UseInfiniteApiListQueryResult,
  UseInfiniteApiQueryResult,
} from "@libs/@types/apiQueries";

export type OrderBy = "ASCENDING" | "DESCENDING";

export const getPagingDetails = (query: ApiQueryResult) => query.apiResponse?.data.pageDetails;

export const flattenPages = <D>(data: UseInfiniteApiListQueryResult<D>["data"]) =>
  data?.pages.flatMap((page) => page.data);

export const getInfiniteQueryPagingDetails = <D>(data: UseInfiniteApiQueryResult<D>["data"]) =>
  data?.pages[0]?.apiResponse.data.pageDetails;

export const getIsLoadingMore = <D>(query: UseInfiniteApiQueryResult<D>) =>
  Boolean(!query.isLoading && !query.isError && query.hasNextPage);

export const getInfiniteScrollOptionsFromQuery = <D>(query: UseInfiniteApiQueryResult<D>) => {
  return {
    loading: query.isFetching,
    hasNextPage: Boolean(query.hasNextPage),
    onLoadMore: query.fetchNextPage,
    disabled: Boolean(query.error),
  };
};

export const getQueryKey = <D extends Domains, A extends ApiName<D>>(domain: D, apiCall: A) => {
  return [domain, apiCall];
};

export const makeQuery: MakeQuery =
  (options) =>
  ({ args, queryOptions }) =>
  ({ api }) => ({
    queryKey: [getQueryKey(...options.queryKey), args],
    queryFn: () => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;

      return method(...options.formatParams(args)) as Promise<
        ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>
      >;
    },
    ...options.queryOptions,
    ...queryOptions,
  });

export const makeQueryOptionalArgs: MakeOptionalArgsQuery =
  (options) =>
  (callOptions) =>
  ({ api }) => ({
    queryKey: [getQueryKey(...options.queryKey), callOptions?.args],
    queryFn: () => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;

      return method(...options.formatParams(callOptions?.args)) as Promise<
        ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>
      >;
    },
    ...options.queryOptions,
    ...callOptions?.queryOptions,
  });

export const makeInfiniteQuery: MakeInfiniteQuery =
  (options) =>
  ({ args, queryOptions }) =>
  ({ api }) => ({
    // We add an "infinite" key to the end, because if a `useQuery` and a `useInfiniteQuery` get called with the same params,
    // for the same API, there is a collision on the cache. Normally this would be ok, but infinite queries wrap the cache
    // entry with pagination information, so it will break the other query, or visa versa
    //
    // "infinite" is in the last position so that both the infinite and normal query caches
    // for a given api can be decached by passing all of the previous query key items.
    queryKey: [getQueryKey(...options.queryKey), args, "infinite"],
    queryFn: ({ pageParam }) => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;
      const params = options.formatParams({
        ...args,
        pageNumber: pageParam === undefined ? args.pageNumber : (pageParam as number),
      });

      return method(...params) as Promise<ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>>;
    },
    ...options.queryOptions,
    ...queryOptions,
  });

export const getNextPageParam = <Page extends ApiResponse>(lastPage: Page | undefined) => {
  const SECOND_PAGE = 2;

  if (!lastPage) {
    return SECOND_PAGE;
  }

  const pageDetails = lastPage.data.pageDetails;
  const pageNumber = pageDetails?.pageNumber;
  const totalPages = pageDetails?.totalPages;

  if (pageNumber === undefined || pageNumber === totalPages || totalPages === 0) {
    // Per react-query docs, must return `undefined` if we've reached the last page
    // eslint-disable-next-line consistent-return
    return;
  }

  return pageNumber + 1;
};
