import { TIME_PLACES } from "@libs/utils/date";

const NOON = 12;
const MAX_MINUTE = 59;
const MAX_HOUR = 23;

export type AmPm = "AM" | "PM";
export type TimeInputValues = {
  hours: string;
  minutes: string;
  seconds: string;
  amPm: AmPm | undefined;
};

const nonNumericRegex = /\D/;

export const amPmOptions: { value: AmPm; label: string }[] = [
  {
    label: "AM",
    value: "AM",
  },
  {
    label: "PM",
    value: "PM",
  },
];

export const padTime = (time: string) => time.padStart(TIME_PLACES, "0");

const parseTimePart = (part: string | undefined) => {
  const parsed = part ? Number.parseInt(part) : Number.NaN;

  return Number.isNaN(parsed) || part === undefined ? "" : parsed > MAX_MINUTE || parsed < 0 ? "00" : part;
};

// A function to convert a date-fns time string format of H:mm:ss to input
// display values to be used by TimeInput
export const timeStringToInputValues = (value: string): TimeInputValues => {
  if (!value.length) {
    return {
      hours: "",
      minutes: "",
      seconds: "",
      amPm: "AM",
    };
  }

  const [hours, minutes, seconds] = value.split(":");

  let amPm: AmPm | undefined;

  const parsedHours = Number.parseInt(hours);

  if (parsedHours >= NOON && parsedHours <= MAX_HOUR) {
    amPm = "PM";
  } else if (!Number.isNaN(parsedHours)) {
    amPm = "AM";
  }

  let displayHours = "";

  if (!Number.isNaN(parsedHours)) {
    if (parsedHours === 0 || parsedHours > MAX_HOUR) {
      displayHours = "12";
    } else if (parsedHours > NOON) {
      displayHours = `${parsedHours % NOON}`;
    } else {
      displayHours = `${parsedHours}`;
    }
  }

  return {
    hours: displayHours,
    minutes: parseTimePart(minutes),
    seconds: parseTimePart(seconds),
    amPm,
  };
};

export const inputValuesToTimeString = ({ hours, minutes, seconds, amPm }: TimeInputValues) => {
  let hoursValue = Number.parseInt(hours);

  if (!Number.isNaN(hoursValue) && amPm !== undefined) {
    if (amPm === "AM" && hoursValue === NOON) {
      hoursValue = 0;
    } else if (amPm === "PM" && hoursValue < NOON) {
      hoursValue = NOON + hoursValue;
    }
  }

  return [Number.isNaN(hoursValue) ? "" : padTime(`${hoursValue}`), minutes, seconds].join(":");
};

export const getNextValueForHourKeydown = (
  currentValue: string,
  key: string,
  amPm: AmPm | undefined
): Partial<TimeInputValues> | null => {
  if (key === "ArrowUp" || key === "ArrowDown") {
    const parsed = Number.parseInt(currentValue);

    if (Number.isNaN(parsed) || amPm === undefined) {
      return {
        hours: "1",
        amPm: "PM",
      };
    }

    if (key === "ArrowUp") {
      if (parsed === NOON - 1) {
        return {
          hours: "12",
          amPm: amPm === "PM" ? "AM" : "PM",
        };
      }

      if (parsed >= NOON) {
        return {
          hours: `${(parsed % NOON) + 1}`,
        };
      }

      return { hours: `${parsed + 1}` };
    }

    if (parsed === NOON) {
      return {
        hours: "11",
        amPm: amPm === "PM" ? "AM" : "PM",
      };
    }

    if (parsed <= 1) {
      return {
        hours: "12",
      };
    }

    return { hours: `${parsed - 1}` };
  }

  return null;
};

export const getNextValueForMinuteKeydown = (
  currentValue: string,
  key: string,
  hours: string,
  amPm: AmPm | undefined
): Partial<TimeInputValues> | null => {
  if (key === "ArrowUp" || key === "ArrowDown") {
    const parsed = Number.parseInt(currentValue);

    if (Number.isNaN(parsed)) {
      return {
        minutes: "00",
      };
    }

    if (key === "ArrowUp") {
      if (parsed === MAX_MINUTE) {
        return {
          minutes: "00",
          ...getNextValueForHourKeydown(hours, "ArrowUp", amPm),
        };
      }

      return {
        minutes: padTime(`${parsed + 1}`),
      };
    }

    if (parsed === 0) {
      return {
        minutes: "59",
        ...getNextValueForHourKeydown(hours, "ArrowDown", amPm),
      };
    }

    return {
      minutes: padTime(`${parsed - 1}`),
    };
  }

  return null;
};

export const constrainHourValue = (value: string) => {
  const hoursValue = value.replace(nonNumericRegex, "").slice(0, TIME_PLACES);

  if (hoursValue.length === 1 && hoursValue === "0") {
    return "";
  }

  if (hoursValue.length === TIME_PLACES) {
    const parsed = Number.parseInt(hoursValue);

    if (Number.isNaN(parsed)) {
      return "";
    }

    if (parsed > NOON) {
      return hoursValue.slice(0, 1);
    }

    return hoursValue;
  }

  return hoursValue;
};

export const constrainMinuteValue = (value: string) => {
  const minutesValue = value.replace(nonNumericRegex, "").slice(0, TIME_PLACES);

  if (minutesValue.length === TIME_PLACES) {
    const parsed = Number.parseInt(minutesValue);

    if (Number.isNaN(parsed)) {
      return "";
    }

    if (parsed > MAX_MINUTE) {
      return minutesValue.slice(0, 1);
    }

    return minutesValue;
  }

  return minutesValue;
};
