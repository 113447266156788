import React, { useMemo } from "react";
import { noop } from "@libs/utils/noop";

type BooleanFunction = () => boolean;
type BooleanInitialState = boolean | BooleanFunction;
export type UseBooleanResult = {
  isOn: boolean;
  isOff: boolean;
  on: Func;
  off: Func;
  toggle: Func;
  set: React.Dispatch<React.SetStateAction<boolean>>;
};

export const makeInitialState = (initialValue: boolean) => {
  const initialState: UseBooleanResult = {
    isOn: initialValue === true,
    isOff: initialValue === false,
    on: noop,
    off: noop,
    toggle: noop,
    set: noop,
  };

  return initialState;
};

export function useBoolean(initialState: BooleanInitialState) {
  const [state, setState] = React.useState(initialState);
  const on = React.useCallback(() => {
    setState(true);
  }, []);
  const off = React.useCallback(() => {
    setState(false);
  }, []);
  const toggle = React.useCallback(() => {
    setState((last) => !last);
  }, []);

  return useMemo<UseBooleanResult>(
    () => ({
      isOn: state === true,
      isOff: state === false,
      on,
      off,
      toggle,
      set: setState,
    }),
    [state, on, off, toggle]
  );
}
