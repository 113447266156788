import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";
import { isSupportIdentityToken } from "utils/auth";

export const loader =
  ({ storage }: PracticeRouterContext) =>
  async () => {
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);

    if (activityStorage.isRecentlyActive() && tokens.identity) {
      return {
        email: tokens.identity.email,
        accountToken: tokens.account,
        isSupportUser: isSupportIdentityToken(tokens.identity),
      };
    }

    return {
      email: undefined,
      accountToken: undefined,
      isSupportUser: false,
    };
  };
