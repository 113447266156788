import { captureException } from "@sentry/react";
import { ErrorResponse, HttpResponse } from "@libs/api/generated-api";

export const resolveErrorJson = async (response: Response) => {
  try {
    // We attempt deserializing the response, if deserialization fails, we then attempt to read the response as text
    const jsonResponse = response.clone() as HttpResponse<undefined, ErrorResponse | undefined>;

    // If deserialization is successful, then the code making the request will hander the successfully deserialized error
    jsonResponse.error = (await jsonResponse.json()) as ErrorResponse;

    return jsonResponse;
  } catch {
    const textResponse = response.clone();
    const textContent = await textResponse.text();

    captureException("Api Deserialization Error", {
      extra: {
        status: textContent,
        statusText: response.statusText,
      },
    });

    return response as HttpResponse<undefined, ErrorResponse | undefined>;
  }
};
