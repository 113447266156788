import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export const MailToLink: FC<{ className?: string; email: string; children?: ReactNode }> = ({
  className,
  email,
  children,
}) => (
  <a className={cx("font-sansSemiBold text-primaryTheme", className)} href={`mailto:${email}`}>
    {children ?? email}
  </a>
);
