import { createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import { getAbsoluteUrl, getFullUrl } from "@libs/utils/location";

const Context = createContext("");

Context.displayName = "Origin";
export const OriginContext = Context;
export const useOrigin = () => useContext(OriginContext);
export const useCurrentUrl = () => {
  const location = useLocation();
  const origin = useOrigin();

  return getAbsoluteUrl(origin, getFullUrl(location));
};
