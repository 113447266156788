import { isBefore, isValid } from "date-fns";
import { getLocalDate, isISOTime } from "@libs/utils/date";
import { isDomain } from "@libs/utils/domain";
import { isUrl } from "@libs/utils/url";
import { isValidPassword } from "@libs/utils/password";
import {
  phoneNumberRegex,
  routingNumberRegex,
  emailRegex,
  alphaNumericRegex,
  numericAnydeskIdRegex,
  taxIdRegex,
} from "@libs/utils/regex";
import { isFormattedSSN, isSSN } from "@libs/utils/ssn";
import { isNumber, isString, isDefined } from "@libs/utils/types";

const isEmptyString = (value: string) => value.trim().length === 0;

export const hasValue = (value: unknown) =>
  isDefined(value) &&
  (!isNumber(value) || !Number.isNaN(value)) &&
  (!isString(value) || !isEmptyString(value));

export const hasNoValue = (value: unknown) => !hasValue(value);

export const containsString = (compareValue: string) => (value: unknown) =>
  hasValue(value) && isString(value) && value.includes(compareValue);
// general
export const required = (value: unknown) => hasValue(value);
export const eq = (compareValue: unknown) => (value: unknown) => !hasValue(value) || compareValue === value;
export const notEq = (compareValue: unknown) => (value: unknown) =>
  !hasValue(value) || compareValue !== value;

// string formats
export const ssn = (value: unknown) => {
  if (hasValue(value) && isString(value)) {
    return isSSN(value);
  }

  return true;
};
export const formattedSsn = (value: unknown) => {
  if (hasValue(value) && isString(value)) {
    return isFormattedSSN(value);
  }

  return true;
};
export const email = (value: unknown) =>
  hasValue(value) && isString(value) ? emailRegex({ exact: true }).test(value) : true;
export const phoneNumber = (value: unknown) =>
  hasValue(value) && isString(value) ? phoneNumberRegex.test(value) : true;
export const taxId = (value: unknown) => (hasValue(value) && isString(value) ? taxIdRegex.test(value) : true);
export const routingNumber = (value: unknown) =>
  hasValue(value) && isString(value) ? routingNumberRegex.test(value) : true;
export const password = (value: unknown) => {
  if (hasValue(value) && isString(value)) {
    return isValidPassword(value);
  }

  return true;
};
export const alphaNumeric = (value: unknown) =>
  hasValue(value) && isString(value) ? alphaNumericRegex.test(value) : true;
export const numericAnydeskId = (value: unknown) => {
  if (hasValue(value) && isString(value)) {
    return numericAnydeskIdRegex.test(value);
  }

  return false;
};
export const domain = (value: unknown) => (hasValue(value) && isString(value) ? isDomain(value) : true);
export const url = (value: unknown) => (hasValue(value) && isString(value) ? isUrl(value) : true);

// numbers
export const min = (minValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value >= minValue : true;

export const max = (maxValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value <= maxValue : true;

export const greaterThan = (baseValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value > baseValue : true;

export const greaterOrEqualTo = (baseValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value >= baseValue : true;

export const dayGreaterOrEqualTo = (baseValue: Date) => (value: unknown) => {
  return hasValue(value) && isValid(value) && value instanceof Date ? !isBefore(value, baseValue) : true;
};

// Equivalent of dayGreaterOrEqualTo, with two ISO strings
export const dayGreaterOrEqualToISO = (baseValue: string) => (value: unknown) => {
  if (!hasValue(value) || !isString(value)) {
    return true;
  }

  const dateValue = getLocalDate(value);

  return isValid(dateValue) ? !isBefore(dateValue, getLocalDate(baseValue)) : true;
};

export const lessThan = (baseValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value < baseValue : true;

export const lessOrEqualTo = (baseValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? value <= baseValue : true;

/**
 * Like `lessOrEqualTo`, but uses absolute values before comparison (ignores sign).
 * @param baseValue The base value to compare against.
 * @returns A validator function that returns true if the value is less than or equal to the base
 * value.
 */
export const absLessOrEqualTo = (baseValue: number) => (value: unknown) =>
  hasValue(value) && isNumber(value) ? Math.abs(value) <= Math.abs(baseValue) : true;

export const notInNumberSet = (set: Set<number>) => (value: unknown) =>
  isNumber(value) ? !set.has(value) : true;

// sets
export const minSetSize = (minSize: number) => (value: unknown) => {
  return value instanceof Set ? value.size >= minSize : true;
};

export const maxSetSize = (maxSize: number) => (value: unknown) => {
  return value instanceof Set ? value.size <= maxSize : true;
};

export const exactSetSize = (size: number) => (value: unknown) =>
  value instanceof Set ? value.size === size : true;

// arrays
export const minArrayLen = (minLen: number) => (value: unknown) =>
  Array.isArray(value) ? value.length >= minLen : true;

export const maxArrayLen = (maxLen: number) => (value: unknown) =>
  Array.isArray(value) ? value.length <= maxLen : true;

// string lengths
export const exactLength = (len: number) => (value: unknown) =>
  isString(value) ? value.length === len : true;
export const maxLength = (len: number) => (value: unknown) => (isString(value) ? value.length <= len : true);
export const minLength = (len: number) => (value: unknown) => (isString(value) ? value.length >= len : true);
export const isoTime = (value: unknown) => hasValue(value) && isString(value) && isISOTime(value);
