import { FC, ReactNode } from "react";

interface Props {
  fallback: ReactNode;
  children?: ReactNode;
}

export const SafelyRenderedContent: FC<Props> = ({ children, fallback }) => {
  return <>{children || fallback}</>;
};
