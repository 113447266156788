import { PayrollVO } from "@libs/api/generated-api";
import { PAGE_SIZE } from "@libs/utils/constants";
import {
  ParsedParams,
  StrVal,
  parseEnum,
  NumCSV,
  Enum,
  NumVal,
  withDefault,
  routesConfigHelper,
  BoolVal,
} from "@libs/router/url";
import { EmployeeStatus } from "api/employee/types";
import { DateRangeVal } from "utils/routing/dateRange";
import { timeFormat } from "utils/routing/timeFormat";

export const NO_STATUS_FILTER = "ALL";
export const DEFAULT_STATUS_FILTER = ["ACTIVE", "PENDING"];
export type EmployeeStatusQuery = EmployeeStatus | "ALL";

const EmployeeStatusMap: Record<EmployeeStatusQuery, null> = {
  ALL: null,
  ACTIVE: null,
  INACTIVE: null,
  ARCHIVED: null,
  PENDING: null,
  FURLOUGHED: null,
};

const EmployeeStatusSet = new Set(Object.keys(EmployeeStatusMap));
const EmployeesQueryParams = {
  search: StrVal,
  sortColumn: StrVal,
  orderBy: Enum<"ASCENDING" | "DESCENDING">().Val(["ASCENDING", "DESCENDING"]),
  status: {
    get: (val: string) => {
      const statuses = val.split(",");
      const parsed = statuses.map((s) => parseEnum(EmployeeStatusSet, s)) as EmployeeStatusQuery[];

      if (parsed.length === 1 && parsed[0] === NO_STATUS_FILTER) {
        return [] as EmployeeStatusQuery[];
      }

      return parsed;
    },
    set: NumCSV.set,
    defaultValue: DEFAULT_STATUS_FILTER,
  },
};

const FromQueryParam = {
  from: StrVal,
};

export const EmployeeParams = {
  id: NumVal,
};

export const EmployeeQuery = {
  ...FromQueryParam,
  edit: BoolVal,
};

const DocumentsQuery = {
  folderId: NumVal,
  docId: NumVal,
  ...EmployeeQuery,
};

const ArchyReferralQuery = {
  /* eslint-disable @typescript-eslint/naming-convention */
  archy_user_referral: NumVal,
  archyfname: StrVal,
  /* eslint-enable @typescript-eslint/naming-convention */
  ...EmployeeQuery,
};

const PayrollItemPathParams = {
  payrollId: StrVal,
};

const PayrollQueryParams = {
  page: withDefault(NumVal, 1),
  pageSize: withDefault(NumVal, PAGE_SIZE),
  payrollYear: NumVal,
  payrollType: Enum<PayrollVO["type"]>().Val(["OFF_CYCLE", "REGULAR", "DISMISSAL"]),
};

const EditPayrollQueryParams = {
  ...FromQueryParam,
};

const RoleIdPathParam = {
  roleId: NumVal,
};

const PayrollDetailQueryParams = {
  root: StrVal,
  ...FromQueryParam,
};

export type PayrollQuery = ParsedParams<typeof PayrollQueryParams>;
export type PayrollFilterQuery = Pick<PayrollQuery, "payrollType" | "payrollYear">;

export type DateRangeTab = "calendar" | "payPeriod";

const TimeSheetQuery = {
  ...EmployeeQuery,
  timeFormat,
  dateRange: DateRangeVal,
  dateRangeTab: Enum<DateRangeTab>().Val(["payPeriod", "calendar"]),
};

export const EmployeeRoutesConfig = routesConfigHelper({
  employee: {
    path: "/employees/profiles/:id",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  employeeTimesheet: {
    path: "/employees/profiles/:id/timesheet",
    params: EmployeeParams,
    query: TimeSheetQuery,
  },
  employeeDocuments: {
    path: "/employees/profiles/:id/documents",
    params: EmployeeParams,
    query: DocumentsQuery,
  },
  employeeEmployment: {
    path: "/employees/profiles/:id/employment",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  archyReferral: {
    path: "/employees/profiles/:id/archy-referral",
    params: EmployeeParams,
    query: ArchyReferralQuery,
  },
  employeeEmploymentGusto: {
    path: "/employees/profiles/:id/employment/gusto",
    params: EmployeeParams,
  },
  employees: {
    path: "/employees",
    query: EmployeesQueryParams,
  },
  payroll: {
    path: "/employees/payrolls",
    query: PayrollQueryParams,
  },
  payrollDetail: {
    path: "/employees/payrolls/:payrollId",
    params: PayrollItemPathParams,
    query: PayrollDetailQueryParams,
  },
  payrollDetailEmployees: {
    path: "/employees/payrolls/:payrollId/employees",
    params: PayrollItemPathParams,
    query: PayrollDetailQueryParams,
  },
  payrollDetailTaxes: {
    path: "/employees/payrolls/:payrollId/taxes",
    params: PayrollItemPathParams,
    query: PayrollDetailQueryParams,
  },
  editPayroll: {
    path: "/employees/payrolls/:payrollId/edit",
    params: PayrollItemPathParams,
    query: EditPayrollQueryParams,
  },
  roles: {
    path: "/employees/roles",
  },
  roleCreate: {
    path: "/employees/roles/create",
    query: FromQueryParam,
  },
  roleEdit: {
    path: "/employees/roles/:roleId/edit",
    params: RoleIdPathParam,
    query: FromQueryParam,
  },
  roleCopy: {
    path: "/employees/roles/:roleId/copy",
    params: RoleIdPathParam,
    query: FromQueryParam,
  },
  electronicPrescribing: {
    path: "/employees/electronic-prescribing",
  },
});

export type EmployeesQuery = ParsedParams<(typeof EmployeeRoutesConfig.employees)["query"]>;
export type EmployeesQueryUpdates = Partial<EmployeesQuery>;
