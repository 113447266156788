import { useEffect } from "react";

export const useDisableScrollBody = (options?: { enabled?: boolean }) => {
  const enabled = options?.enabled ?? true;

  useEffect(() => {
    if (enabled) {
      const { body } = document;
      const existingStyle = body.getAttribute("style")?.trim();

      if (existingStyle) {
        const hasLineTermination = existingStyle.slice(-1) === ";";
        const prependedStyle = hasLineTermination ? existingStyle : `${existingStyle};`;

        body.setAttribute("style", `${prependedStyle} overflow: hidden;`);
      } else {
        body.setAttribute("style", "overflow: hidden;");
      }

      return () => {
        if (existingStyle) {
          body.setAttribute("style", existingStyle);
        } else {
          body.removeAttribute("style");
        }
      };
    }

    return undefined;
  }, [enabled]);
};
