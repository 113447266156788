import { Enum, routesConfigHelper, StrVal, BoolVal, NumberOrNew } from "@libs/router/url";
import { PatientPathParams } from "utils/routing/patient";

export type PatientInsuranceDetailsStep = "info" | "carrier" | "coverage" | "limitations";

export const PatientInsuranceQueryParams = {
  from: StrVal,
  isAdding: BoolVal,
};

const InsuranceDetailsPathParams = {
  ...PatientPathParams,
  insuranceId: NumberOrNew,
};

const patientInsuranceStepParams = {
  params: InsuranceDetailsPathParams,
  query: PatientInsuranceQueryParams,
};

export const PatientInsuranceRoutesConfig = routesConfigHelper({
  patientInsurance: {
    path: "/patients/:patientId/insurance",
    params: PatientPathParams,
  },
  patientInsuranceDetails: {
    path: "/patients/:patientId/insurance/:insuranceId",
    params: InsuranceDetailsPathParams,
    query: PatientInsuranceQueryParams,
  },
  patientInsuranceStep: {
    path: "/patients/:patientId/insurance/:insuranceId/:step",
    params: {
      ...InsuranceDetailsPathParams,
      step: Enum<PatientInsuranceDetailsStep>().Val(["info", "carrier", "coverage", "limitations"]),
    },
    query: PatientInsuranceQueryParams,
  },
  patientInsuranceInfoStep: {
    path: "/patients/:patientId/insurance/:insuranceId/info",
    ...patientInsuranceStepParams,
  },
  patientInsuranceCarrierStep: {
    path: "/patients/:patientId/insurance/:insuranceId/carrier",
    ...patientInsuranceStepParams,
  },
  patientInsuranceCoverageStep: {
    path: "/patients/:patientId/insurance/:insuranceId/coverage",
    ...patientInsuranceStepParams,
  },
  patientInsuranceLimitationsStep: {
    path: "/patients/:patientId/insurance/:insuranceId/limitations",
    ...patientInsuranceStepParams,
  },
});
