import { getActivityStorage, useActivityStorage } from "@libs/storage/activity";
import { IDLE_TIMEOUT } from "config/react";

export const getPracticeActivityStorage = (storage: Storage) => {
  return getActivityStorage(storage, IDLE_TIMEOUT);
};

export const usePracticeActivityStorage = () => {
  return useActivityStorage(IDLE_TIMEOUT);
};
