import { QueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";

export const invalidatePendingClaims = (queryClient: QueryClient, { practiceId }: { practiceId: number }) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getClaim")]);
};

export const invalidateClaimsList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries")]);
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview")]);
};

export const invalidateClaim = (
  queryClient: QueryClient,
  { practiceId, claimUuid }: { practiceId: number; claimUuid: string }
) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId, claimUuid }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
};
