// Sentry
import {
  setUser as sentrySetUser,
  setTags as setSentryTags,
  setExtras as setSentryExtras,
} from "@sentry/react";
import { EmploymentDetailsVO, PracticeInfoVO } from "@libs/api/generated-api";

export const setSentryUserProperties = (user: { practiceId: number; id: number } | null) => {
  sentrySetUser(user ? { id: String(user.id) } : null);
  setSentryTags({
    ["practice.id"]: user ? String(user.practiceId) : null,
  });
};

export const setSentryEmployeeProperties = (employeeStatus: EmploymentDetailsVO["status"] | null) => {
  setSentryExtras({
    ["user.status"]: employeeStatus ?? null,
  });
};

export const setPracticeProperties = (
  practice: Pick<PracticeInfoVO, "name" | "ownerName" | "timezoneId" | "uuid"> | null
) => {
  setSentryExtras({
    ["practice.name"]: practice?.name ?? null,
    ["practice.ownerName"]: practice?.ownerName ?? null,
    ["practice.timezoneId"]: practice?.timezoneId ?? null,
    ["practice.uuid"]: practice?.uuid ?? null,
  });
};
