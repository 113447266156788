import { paths } from "utils/routing/paths";
import { PatientPageTab } from "utils/routing/patient";

export const getPatientLink = (patientId: number, tab: PatientPageTab | undefined) =>
  tab
    ? paths.patientTab({
        patientId,
        tab,
      })
    : paths.patient({ patientId });
