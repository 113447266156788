import { format, addMilliseconds } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { PayrollLineItemVO, PracticeInfoVO } from "@libs/api/generated-api";
import { formatShortDayOfMonth, getLocalDate } from "@libs/utils/date";

export const practicePaysDoubleOvertime = (practice: PracticeInfoVO) =>
  practice.addressDetails?.state === "CA";

export const getDeadlineDate = (practiceTimezoneId: string, deadline: string) => {
  const date = getLocalDate(deadline, "15:30:00");
  const ms = getTimezoneOffset(practiceTimezoneId, date) - getTimezoneOffset("America/Los_Angeles", date);

  return addMilliseconds(date, ms);
};

// Formats a 3:30PM PT date time as the practice date time.
// 3:30PM PT is when Gusto make debits on any given day.
export const formatDeadlineDate = (practiceTimezoneId: string, deadline: string) => {
  const shiftedDate = getDeadlineDate(practiceTimezoneId, deadline);

  return `${format(shiftedDate, "h:mm a")} on ${formatShortDayOfMonth(shiftedDate)}`;
};

export const hasUnresolvedPostTaxDeductions = (lineItems?: PayrollLineItemVO[]) => {
  return Boolean(lineItems?.some((li) => li.subLineItems.postTaxDeductions.some((ptd) => ptd.errors.length)));
};

export const hasPendingLineItems = (lineItems?: PayrollLineItemVO[]) => {
  return Boolean(lineItems?.some((item) => item.status === "PENDING" && !item.isSkipped));
};

export const hasNotOnboardedLineItems = (
  lineItems?: PayrollLineItemVO[],
  options?: { allowSkipped: boolean }
) => {
  return Boolean(
    lineItems?.some((item) =>
      options?.allowSkipped ? !item.isOnboardedWithGusto && !item.isSkipped : !item.isOnboardedWithGusto
    )
  );
};
