import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { parseParams, isParamParseError, RouteConfig } from "@libs/router/url";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTypedPathParams = <R extends RouteConfig<string, any, any>>(
  routeConfig: R,
  options?: { nullable?: boolean }
) => {
  const location = useLocation();

  const params = useMemo(() => {
    const match = matchPath({ path: routeConfig.path, end: false }, location.pathname);

    if (!match) {
      if (!options?.nullable) {
        throw new Error(
          `usePathParams called on a route it doesn't match. pathname: ${location.pathname} routeName: ${routeConfig.path}`
        );
      }

      return null;
    }

    try {
      return parseParams(routeConfig.params ?? {}, match.params);
    } catch (e) {
      if (isParamParseError(e)) {
        if (!options?.nullable) {
          throw e;
        }

        return null;
      }

      throw e;
    }
  }, [location.pathname, routeConfig, options?.nullable]);

  return params;
};
