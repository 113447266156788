import { QueryClient } from "@tanstack/react-query";
import { retryCodes } from "@libs/utils/statusCodes";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";

const retryConfig = {
  retry: (failureCount: number, error: unknown) => {
    if (isHttpResponseError(error)) {
      return retryCodes.has(error.status) && failureCount < MAX_RETRIES;
    }

    return false;
  },
};

const MAX_RETRIES = 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: retryConfig,
    mutations: retryConfig,
  },
});
