import { Dispatch, SetStateAction, useCallback, useState } from "react";

export const useObjectState = <T extends object>(
  initialState: T | (() => T)
): [T, (partial: Partial<T>) => void, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(initialState);
  const updateState = useCallback((partial: Partial<T>) => setState((last) => ({ ...last, ...partial })), []);

  return [state, updateState, setState];
};
