import { createContext, useContext, FC, PropsWithChildren } from "react";
import type { EnvValues } from "env";

const Context = createContext<EnvValues>({} as EnvValues);

Context.displayName = "EnvContext";

export const useEnvContext = () => useContext(Context);

export const EnvContextProvider: FC<PropsWithChildren<{ value: EnvValues }>> = (props) => {
  return <Context.Provider {...props} />;
};
