import { ButtonHTMLAttributes, FC, PropsWithChildren, ReactNode } from "react";

import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { IconThemes, Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { Spinner } from "@libs/components/UI/Spinner";

interface BaseMenuOption
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "id" | "value" | "type" | "className" | "onClick"> {
  label: ReactNode;
  SvgIcon?: IconComponent;
  iconTheme?: IconThemes;
  isLoading?: boolean;
  tooltip?: Pick<FloatingTooltipProps, "content" | "theme">;
  highlighted?: boolean;
}

type OptionId = string | number;

interface MenuOptionType<ID extends OptionId, V> extends BaseMenuOption {
  id: ID;
  value: V;
}

export interface MenuOptionValue<V extends ValueTypes> extends BaseMenuOption {
  value: V;
}

type MenuOption<ID extends OptionId, TV, V extends ValueTypes> = MenuOptionType<ID, TV> | MenuOptionValue<V>;

const MenuOptionFlex: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex items-center gap-x-2">{children}</div>;
};

const MenuOptionLabel: FC<PropsWithChildren<{ disabled?: boolean }>> = ({ children, disabled }) => {
  return (
    <div
      className={cx(
        "flex-1 text-left text-xs text-greyDark dark:text-whiteLight",
        disabled && "text-opacity-50"
      )}
    >
      {children}
    </div>
  );
};

export const MenuOptionPaint: FC<PropsWithChildren<{ disabled?: boolean; highlighted?: boolean }>> = ({
  children,
  disabled,
  ...props
}) => {
  return (
    <div
      className={cx(
        "py-1.5 px-3 rounded-sm",
        // The hover state is handled by the parent component if `highlighted`
        // is provided. If it is not provided, then we want to handle the hover
        // state using CSS.
        disabled
          ? "cursor-default"
          : props.highlighted == null
            ? "hover:bg-slate-100 dark:hover:bg-black"
            : props.highlighted && "bg-slate-100 dark:bg-black"
      )}
    >
      {children}
    </div>
  );
};

// helper functions when creating dynamic options lists that ensure the options are typed
export const createMenuOption = <
  ID extends OptionId,
  TV,
  V extends ValueTypes,
  Option extends MenuOption<ID, TV, V>,
>(
  option: Option
) => option;
export const createMenuOptions = <
  ID extends OptionId,
  TV,
  V extends ValueTypes,
  Option extends MenuOption<ID, TV, V>,
>(
  ...options: Option[]
) => options;

/*
  Options can be one of two types:
  - If an option only defines a value it has to be unique in the list. This is good when you
    your value is a simple type and is unique across all options.
  - If an option defines an id prop it has to be unique in the list and the value property c
    can be whatever you want. This is good when you want to have arbitrary data associated with an
    option.
*/

export const MenuOptions = <
  ID extends OptionId,
  TV,
  V extends ValueTypes,
  Option extends MenuOption<ID, TV, V>,
>({
  options,
  onOptionClick,
}: {
  options: Option[];
  onOptionClick: (option: Option) => void;
}) => {
  return (
    <>
      {options.map((option, i) => (
        <MenuOption key={i} option={option} onOptionClick={onOptionClick} />
      ))}
    </>
  );
};

export const GroupedMenuOptions: FC<{
  title: ReactNode;
  children: ReactNode;
}> = ({ title, children }) => {
  return (
    <div>
      <div className="px-3 py-2 font-sansSemiBold text-xxs">{title}</div>
      {children}
    </div>
  );
};

export const MenuOption = <
  ID extends OptionId,
  TV,
  V extends ValueTypes,
  Option extends MenuOption<ID, TV, V>,
>({
  option,
  onOptionClick,
}: {
  option: Option;
  onOptionClick: (option: Option) => void;
}) => {
  const { SvgIcon, iconTheme, label, isLoading, value, tooltip, highlighted, ...buttonProps } = option;

  let key = String(value);

  if ("id" in buttonProps) {
    key = String(buttonProps.id);
    delete buttonProps["id"];
  }

  return (
    <FloatingTooltip key={key} content={tooltip?.content ?? ""} theme={tooltip?.theme}>
      <div>
        <button
          disabled={isLoading}
          type="button"
          className="block w-full"
          role="menuitem"
          onClick={() => onOptionClick(option)}
          {...buttonProps}
        >
          <MenuOptionPaint disabled={buttonProps.disabled} highlighted={highlighted}>
            <MenuOptionFlex>
              {SvgIcon && !isLoading ? (
                <Icon disabled={buttonProps.disabled} SvgIcon={SvgIcon} theme={iconTheme} />
              ) : isLoading ? (
                <div className="flex items-center justify-center w-5 h-5">
                  <Spinner variant="secondary" animation="border" size="sm" />
                </div>
              ) : null}
              <MenuOptionLabel disabled={buttonProps.disabled}>{label}</MenuOptionLabel>
            </MenuOptionFlex>
          </MenuOptionPaint>
        </button>
      </div>
    </FloatingTooltip>
  );
};
