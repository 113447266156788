import { useMemo } from "react";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";

const version = "v1";

export const getActivityStorage = (storage: Storage, expires: number) => {
  const activityStorage = wrapStorage<true, "activity">("activity", storage);

  return {
    isRecentlyActive: () => {
      return Boolean(activityStorage.get("recentlyActive", version));
    },
    setRecentlyActive: () => {
      activityStorage.set("recentlyActive", true, {
        version,
        expires,
      });
    },
  };
};

export const useActivityStorage = (expires: number) => {
  const { localStorage } = useStorageContext();

  return useMemo(() => getActivityStorage(localStorage, expires), [localStorage, expires]);
};
