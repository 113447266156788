import { FC } from "react";
import { cx } from "@libs/utils/cx";

interface Props {
  className?: string;
}

export const RequiredAsterisk: FC<Props> = ({ className }) => {
  return <span className={cx("text-red-500 ml-1", className)}>*</span>;
};
