import { HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";

interface Props extends HTMLAttributes<HTMLSpanElement> {
  indent?: boolean;
}
export const FormFieldError: React.FC<Props> = ({ indent = false, className, ...props }) => {
  return (
    <span
      data-form-field-error
      className={cx("text-red-500 text-xxs block", className, indent && "pl-4")}
      {...props}
    />
  );
};
