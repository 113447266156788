import { captureException } from "@sentry/react";
import { Auth } from "aws-amplify";
import { IDENTITY_EMAIL_NOT_FOUND, getTokenErrorReason } from "@libs/utils/cognito";
import { getAccountTokenStorage } from "@libs/storage/accountToken";

type IdTokenField = "username" | "email" | "firstName" | "lastName";
export const getIdentityToken = async () => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  const jwt = idToken.getJwtToken();

  const userFields: Record<IdTokenField, string> = [
    { field: "username" as const, cognitoField: "cognito:username" as const },
    { field: "email" as const, cognitoField: "email" as const },
    { field: "firstName" as const, cognitoField: "given_name" as const },
    { field: "lastName" as const, cognitoField: "family_name" as const },
  ].reduce(
    (acc, { field, cognitoField }) => {
      if (typeof idToken.payload[cognitoField] === "string") {
        acc[field] = idToken.payload[cognitoField];
      }

      return acc;
    },
    {} as Record<IdTokenField, string>
  );

  if (!userFields.email || !userFields.username) {
    throw new Error(IDENTITY_EMAIL_NOT_FOUND);
  }

  return {
    token: jwt,
    ...userFields,
  };
};

const getTokens = async (storage: Storage) => {
  const identity = await getIdentityToken();
  const accountTokenStorage = getAccountTokenStorage(storage);

  return {
    identity,
    account: accountTokenStorage.getAccountToken(identity.email) ?? undefined,
  };
};

// Allow errors to bubble so UI can take action on token errors
export const getTokensForApi = async (storage: Storage) => {
  const tokens = await getTokens(storage);

  return {
    identity: tokens.identity.token,
    account: tokens.account?.token,
  };
};

// Log errors but if they happen the users are considered unauthenticated
export const getTokensForAuthCheck = async (storage: Storage) => {
  try {
    return await getTokens(storage);
  } catch (e) {
    if (!getTokenErrorReason(e)) {
      captureException(e);
    }

    return {
      identity: undefined,
      account: undefined,
    };
  }
};
