type StringOrNumberKeys<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends number | string ? K : never;
}[keyof T];

type StringOrNumberValueType<
  T extends Record<string, unknown>,
  K extends StringOrNumberKeys<T>,
> = T[K] extends number ? number : T[K] extends string ? string : never;

type ValueType<T extends Record<string, unknown>, K extends StringOrNumberKeys<T>> = T[K] extends
  | number
  | string
  ? T[K]
  : never;

/**
 * Transforms an array of objects into an object where the keys are determined by the provided key parameter.
 *
 * @template O The type of the objects in the input array. Must extend Record<string, string>.
 * @template K The type of the key used to index the objects. Must be a key of O.
 * @param {O[] | Readonly<O[]>} items - The array of objects to be transformed.
 * @param {K} key - The key to be used to index the objects.
 * @returns {Record<O[K], O>} Returns an object where the keys are determined by the provided key parameter.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keyBy = <O extends Record<string, any>, K extends StringOrNumberKeys<O>>(
  items: O[] | Readonly<O[]>,
  key: K
) => {
  return looseTypeKeyBy(items, key) as Record<ValueType<O, K>, O>;
};

/**
 * Transforms an array of objects into an object where the keys are determined by the provided key parameter.
 * This function is a loosely typed version of the `keyBy` function in that the returned object
 * has string keys and values of type O or undefined. This can be used in cases where values being looked up
 * may not be in the array.
 *
 * @param {Array} items - The array of objects to be transformed.
 * @param {string} key - The key to be used to index the objects.
 * @returns {Object} Returns an object where the keys are determined by the provided key parameter.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const looseTypeKeyBy = <O extends Record<string, any>, K extends StringOrNumberKeys<O>>(
  items: O[] | Readonly<O[]>,
  key: K
): Record<StringOrNumberValueType<O, K>, O | undefined> => {
  const result = {} as Record<StringOrNumberValueType<O, K>, O | undefined>;

  items.forEach((item) => {
    const keyValue = item[key] as StringOrNumberValueType<O, K>;

    result[keyValue] = item;
  });

  return result;
};
