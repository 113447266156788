import { ClaimVO } from "@libs/api/generated-api";

export type ClaimInsurancePaymentStatus = ClaimVO["insurancePaymentStatus"];
export type ClaimInsuranceOrdinal = ClaimVO["insuranceOrdinal"];
export type ClaimState = ClaimVO["state"];

export enum FilterStatus {
  NOT_SUBMITTED = "NOT_SUBMITTED",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
  VOIDED = "VOIDED",
}

export type ClaimField =
  | "billingProviderId"
  | "insuranceOrdinal"
  | "patientInsuranceId"
  | "treatingProviderId";

export type ClaimFieldValueMap = Record<ClaimField, number | undefined>;

export const FilterStatusData: Record<
  keyof typeof FilterStatus,
  {
    label: string;
    states?: ClaimState[];
  }
> = {
  NOT_SUBMITTED: {
    label: "Drafts",
    states: ["PENDING", "FAILED"] as ClaimState[],
  },
  SUBMITTED: {
    label: "Sent",
    states: ["SUBMITTED"] as ClaimState[],
  },
  COMPLETED: {
    label: "Completed",
    states: ["COMPLETED"] as ClaimState[],
  },
  VOIDED: {
    label: "Voided",
    states: ["VOIDED"] as ClaimState[],
  },
};

export const optionValueByInsuranceOrdinal = {
  PRIMARY: 0,
  SECONDARY: 1,
  OTHER: 2,
};

/* eslint-disable @typescript-eslint/naming-convention */
export const insuranceOrdinalByOptionValue: Record<number, ClaimInsuranceOrdinal> = {
  0: "PRIMARY",
  1: "SECONDARY",
  2: "OTHER",
};
/* eslint-enable @typescript-eslint/naming-convention */

export enum PrintVersion {
  TWENTY_NINETEEN = 2019,
  TWENTY_TWENTY_FOUR = 2024,
}
