export const isValidJSON = (value: string) => {
  try {
    const json = JSON.parse(value) as unknown;

    if (json && typeof json === "object") {
      return true;
    }

    return false;
  } catch {
    return false;
  }
};
