export const isUrl = (value: string) => {
  try {
    new URL(value);

    return true;
  } catch {
    return false;
  }
};

export const urlFormatter = (value?: string) => {
  if (!value) {
    return "";
  }

  if (value.includes(".") && !value.includes("://")) {
    return `http://${value}`;
  }

  return value;
};
