import { ButtonHTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";

export const cxMenuButtonStyles = {
  layout: `
    flex
    items-center
    text-xs
    item-align
    py-2
    px-3
    w-full
    text-left
  `,
  interaction: (params: { disabled?: boolean }) =>
    cx(params.disabled ? "cursor-default opacity-50" : "hover:bg-slate-100 dark:hover:bg-black"),
};
type StyleProps = {
  interactionStyles?: boolean;
  tooltip?: Omit<FloatingTooltipProps, "children">;
};

export type MenuOptionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & StyleProps;

export const MenuOptionButton: React.FC<MenuOptionButtonProps> = ({
  interactionStyles = true,
  tooltip,
  ...props
}) => {
  return (
    <FloatingTooltip content={tooltip?.content} theme={tooltip?.theme}>
      <button
        {...props}
        // eslint-disable-next-line react/button-has-type
        type={props.type ?? "button"}
        className={cx(
          cxMenuButtonStyles.layout,
          interactionStyles && cxMenuButtonStyles.interaction({ disabled: props.disabled }),
          props.className
        )}
      />
    </FloatingTooltip>
  );
};

type MenuOptionLinkProps = LinkProps & { disabled?: boolean } & StyleProps;
export const MenuOptionLink: React.FC<MenuOptionLinkProps> = ({ interactionStyles = true, ...props }) => {
  return (
    <Link
      {...props}
      className={cx(
        cxMenuButtonStyles.layout,
        interactionStyles && cxMenuButtonStyles.interaction({ disabled: props.disabled }),
        props.className
      )}
    />
  );
};

export const MenuOptionExternalLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { disabled?: boolean }
> = ({ className, disabled, children, ...props }) => {
  return (
    <a
      {...props}
      className={cx(cxMenuButtonStyles.layout, cxMenuButtonStyles.interaction({ disabled }), className)}
    >
      {children}
    </a>
  );
};
