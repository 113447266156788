import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { paths } from "utils/routing/paths";
import { useOnboardingEmployee } from "components/Onboarding/useOnboardingEmployee";

// If user finds themselves in practice onboarding when they haven't been onboarded
// as an employee, they get redirected back to employee onboarding
export const PracticeOnboardingGuard: FC<PropsWithChildren> = ({ children }) => {
  const { employmentDetails } = useOnboardingEmployee();

  return employmentDetails.status === "PENDING" ? (
    <Navigate replace to={paths.onboarding({ step: "personal-info" })} />
  ) : (
    children
  );
};
