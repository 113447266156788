import { FC, useEffect } from "react";
import { isDefined } from "@libs/utils/types";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getPostSignOutStorage } from "storage/postSignOut";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { paths } from "utils/routing/paths";

export const PostSignOutRoute: FC = () => {
  const storage = useStorageContext();
  const postSignOutStorage = getPostSignOutStorage(storage.localStorage);
  const params = postSignOutStorage.getPostSignOutParams();

  useEffect(() => {
    postAuthChannelMessage({
      type: "signOut",
      reason: params?.signOutReason,
      // If a user is signed out with a returnUrl, it means we want to return
      // the user to where they were after signing in. However we don't know the
      // current url of the other tabs. By broadcasting addReturnUrl, the other
      // tabs know to add a return url when navigating to the sign in page
      addReturnUrl: isDefined(params?.returnUrl),
    });

    const signInParams = {
      signOutReason: params?.signOutReason,
      returnUrl: params?.returnUrl,
      lastUserId: params?.lastUserId,
    };

    window.location.assign(
      params?.to ?? (params?.isSupportUser ? paths.supportSignIn(signInParams) : paths.signIn(signInParams))
    );
  }, [params]);

  return <LoadingOverlaySpinner loadingText="Signing Out" centerVertically />;
};
