import { PropsWithChildren, ReactNode, Ref } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { Form, FormProps } from "@libs/components/UI/Form";

export const FLYOVER_CONTENT_PADDING_DEFAULT = "pt-5 px-8 pb-12";

const cxSizes = {
  default: {
    padding: "px-8 py-6",
  },
  sm: {
    padding: "py-4 px-6",
  },
  custom: {
    padding: "",
  },
};

export type FlyoverHeaderProps = {
  className?: string;
  onClose?: () => void;
  children?: ReactNode;
  size?: keyof typeof cxSizes;
};

export const FlyoverHeader: React.FC<FlyoverHeaderProps> = ({
  className,
  children,
  onClose,
  size = "default",
}) => {
  return (
    <div
      className={cx(
        `flex
         flex-none
         border-b
         border-slate-200
         items-center
         dark:border-slate-500
         gap-2`,
        cxSizes[size].padding,
        className
      )}
    >
      <div role="heading" aria-level={1} className="text-base font-sansSemiBold flex-1">
        {children}
      </div>
      {onClose && (
        <ButtonIcon
          tooltip={{ content: "Close", theme: "SMALL" }}
          theme="primary"
          SvgIcon={CancelIcon}
          onClick={onClose}
        />
      )}
    </div>
  );
};

export const FlyoverForm: React.FC<FormProps> = (props) => {
  return <Form {...props} className={cx("min-h-0 flex-1 flex flex-col", props.className)} />;
};

export const FlyoverContent: React.FC<{
  className?: string;
  paddingClassName?: string;
  scrollClassName?: string;
  children?: ReactNode;
  containerRef?: Ref<HTMLDivElement>;
}> = ({
  className,
  children,
  paddingClassName = FLYOVER_CONTENT_PADDING_DEFAULT,
  scrollClassName = "overflow-y-auto",
  containerRef,
}) => {
  return (
    <div ref={containerRef} className={cx("min-h-0 flex-1", scrollClassName, paddingClassName, className)}>
      {children}
    </div>
  );
};

export const FlyoverFooterButtons: React.FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return <div className={cx("flex justify-center items-center gap-x-3", className)}>{children}</div>;
};

export const FlyoverFooter: React.FC<{
  className?: string;
  paddingClassName?: string;
  children?: ReactNode;
  actions?: boolean;
}> = ({ paddingClassName = "py-4", children, className, actions = true }) => {
  return (
    <div
      className={cx(
        `border-t-slate-200
         border-t
         dark:border-t-slate-500
         flex-none`,
        paddingClassName,
        className
      )}
    >
      {actions ? <FlyoverFooterButtons>{children}</FlyoverFooterButtons> : children}
    </div>
  );
};
