import { Api, ErrorResponse, HttpResponse } from "@libs/api/generated-api";
import { resolveErrorJson } from "@libs/utils/resolveErrorJson";

type ClientCallbacks = {
  onRequestTokens: () => Promise<{ identity: string; account?: string }>;
  onErrorResponse?: (response: HttpResponse<unknown, ErrorResponse | undefined>) => void;
};

export type ClientProps = {
  baseUrl: string;
  headers: Record<string, string>;
} & ClientCallbacks;

const hasCustomHeaders = (
  headers?: RequestInit["headers"] | undefined
): headers is Record<string, string> => {
  return Boolean(headers && !Array.isArray(headers) && !(headers instanceof Headers));
};

const getAuthorizationHeader = async (
  path: string,
  onRequestTokens: () => Promise<{ identity: string; account?: string }>,
  passedValue: string | undefined
) => {
  if (passedValue) {
    // Token is overridden for custom authorization.
    return { Authorization: passedValue };
  }

  // if the api is a public api a token isn't required
  if (path.startsWith("/api/public/")) {
    return undefined;
  }

  const { identity, account } = await onRequestTokens();

  // Token isn't overridden, use what's returned from getToken
  const authHeaders: Record<string, string> = {
    Authorization: `Bearer ${identity}`,
  };

  if (account) {
    authHeaders["x-gf-account-token"] = account;
  }

  return authHeaders;
};

const getPath = (info: RequestInfo | URL) => {
  if (typeof info === "string") {
    if (info.startsWith("http")) {
      return new URL(info).pathname;
    }

    return info;
  }

  if (info instanceof Request) {
    return new URL(info.url).pathname;
  }

  return info.pathname;
};

const baseFetch = async (
  { onRequestTokens, onErrorResponse }: ClientCallbacks,
  ...fetchParams: Parameters<typeof fetch>
) => {
  const [info, options] = fetchParams;
  const requestHeaders = options?.headers;
  let newOptions = options;

  const path = getPath(info);

  if (hasCustomHeaders(requestHeaders)) {
    const { Authorization, ...otherHeaders } = requestHeaders;

    newOptions = {
      ...options,
      headers: {
        ...otherHeaders,
        ...(await getAuthorizationHeader(path, onRequestTokens, Authorization)),
      },
    };
  }

  const response = await fetch(info, newOptions);

  if (!response.ok) {
    const clonedResponse = await resolveErrorJson(response);

    onErrorResponse?.(clonedResponse);
  }

  return response;
};

export const getFetchBlobApi =
  ({ baseUrl, headers, ...rest }: ClientProps) =>
  async (url: string, request?: RequestInit) => {
    const response = await baseFetch(rest, `${baseUrl}${url}`, {
      ...request,
      headers: { ...headers, ...request?.headers },
    });

    if (!response.ok) {
      throw await resolveErrorJson(response);
    }

    return await response.blob();
  };
export const getFetchApi =
  ({ baseUrl, headers, ...rest }: ClientProps) =>
  async <D>(url: string, request?: RequestInit) => {
    const response = await baseFetch(rest, `${baseUrl}${url}`, {
      ...request,
      headers: { ...headers, ...request?.headers },
    });

    if (!response.ok) {
      throw await resolveErrorJson(response);
    }

    return ((await response.json()) as { data: D }).data;
  };
export const getApiClient = ({ baseUrl, headers, ...rest }: ClientProps) =>
  new Api({
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      return await baseFetch(rest, ...fetchParams);
    },
    baseUrl,
    baseApiParams: {
      headers,
    },
  });
