import { PermissionActionVO } from "@libs/api/generated-api";

/**
 * The effect to use for missing permissions.
 *
 * NOTE: If the permissions in the set `PERMISSIONS_ON_BY_DEFAULT`, we should
 * always use "ALLOW".
 */
export const DOMAIN_EFFECT_IF_MISSING = "DENY";
export const SUB_PERMISSION_EFFECT_IF_MISSING = "ALLOW";

export const SORTED_DOMAINS: PermissionActionVO["domain"][] = [
  "APPOINTMENT_MANAGEMENT",
  "PATIENT_PROFILE",
  "CHAT",
  "LAB_CASE",
  "COMMUNICATION",
  "EMPLOYEE_SETTINGS",
  "CLAIMS",
  "REPORTING",
  "BILLING",
  "PRACTICE_SETTINGS",
];

// List of permission actions as of 10/04/2024. This was created so our permission actions are typed
// since `PermissionActionVO["action"]` is a string.
export type PermissionAction =
  | "ACCESS_ALL"
  | "BULK_MESSAGE"
  | "BULK_MAIL_MESSAGE"
  | "EDIT_ROLE"
  | "RUN_PAYROLL"
  | "ACCESS_FINANCIAL"
  | "ACCESS_OPERATIONAL"
  | "ACCESS_HYGIENE"
  | "ACCESS_ADMINISTRATIVE"
  | "PRINT_DOWNLOAD"
  | "COLLECT_MANAGE"
  | "EDIT_PAYMENT"
  | "DELETE_PAYMENT"
  | "CREATE_REFUND"
  | "ADD_ADJUSTMENT_DISCOUNT"
  | "ADD_ADJUSTMENT_CHARGE"
  | "ACCESS_PRACTICE"
  | "ACCESS_PAYROLL"
  | "ACCESS_SCHEDULING"
  | "ACCESS_INSURANCE"
  | "ACCESS_EMPLOYEES"
  | "ACCESS_CHARTING"
  | "ACCESS_LAB_CASES"
  | "ACCESS_CLAIMS"
  | "ACCESS_PAYMENTS"
  | "ACCESS_COMMS"
  | "ACCESS_FORMS"
  | "ACCESS_SECURITY";

export const SORTED_ACTIONS_BY_DOMAIN: Record<PermissionActionVO["domain"], PermissionAction[]> = {
  APPOINTMENT_MANAGEMENT: ["ACCESS_ALL"],
  PATIENT_PROFILE: ["ACCESS_ALL"],
  CHAT: ["ACCESS_ALL"],
  LAB_CASE: ["ACCESS_ALL"],
  COMMUNICATION: ["ACCESS_ALL", "BULK_MESSAGE", "BULK_MAIL_MESSAGE"],
  EMPLOYEE_SETTINGS: ["ACCESS_ALL", "EDIT_ROLE", "RUN_PAYROLL"],
  CLAIMS: ["ACCESS_ALL"],
  REPORTING: [
    "ACCESS_ALL",
    "ACCESS_FINANCIAL",
    "ACCESS_OPERATIONAL",
    "ACCESS_HYGIENE",
    "ACCESS_ADMINISTRATIVE",
    "PRINT_DOWNLOAD",
  ],
  BILLING: [
    "ACCESS_ALL",
    "COLLECT_MANAGE",
    "EDIT_PAYMENT",
    "DELETE_PAYMENT",
    "CREATE_REFUND",
    "ADD_ADJUSTMENT_DISCOUNT",
    "ADD_ADJUSTMENT_CHARGE",
  ],
  PRACTICE_SETTINGS: [
    "ACCESS_ALL",
    "ACCESS_PRACTICE",
    "ACCESS_PAYROLL",
    "ACCESS_EMPLOYEES",
    "ACCESS_SCHEDULING",
    "ACCESS_INSURANCE",
    "ACCESS_CHARTING",
    "ACCESS_LAB_CASES",
    "ACCESS_CLAIMS",
    "ACCESS_PAYMENTS",
    "ACCESS_COMMS",
    "ACCESS_FORMS",
    "ACCESS_SECURITY",
  ],
};

// Set of DOMAIN permissions that are on by default
export const DOMAINS_LOCKED: Set<PermissionActionVO["domain"]> = new Set([
  "APPOINTMENT_MANAGEMENT",
  "PATIENT_PROFILE",
  "CHAT",
  "LAB_CASE",
]);

// We want to have a function that can return a dynamic tooltip based on the role's name and default ON/OFF state.
export const getLockedDomainTooltip = (isOnByDefault: boolean) =>
  `This permission is ${isOnByDefault ? "on" : "off"} by default for users assigned to this role.`;

export const DOMAIN_DATA = {
  APPOINTMENT_MANAGEMENT: {
    label: "Appointment Management",
    description: "Manage patient appointments and ASAP list. View schedule and daily huddle.",
  },
  PATIENT_PROFILE: {
    label: "Patient Profile & Charting",
    description: "Access patient details, imaging, insurance, documents and charting.",
  },
  CHAT: {
    label: "Intra-Office Chat",
    description: "Access to intra-office chatting.",
  },
  LAB_CASE: {
    label: "Lab Cases",
    description: "Manage patient lab cases.",
  },
  COMMUNICATION: {
    label: "Communications",
    description: "Direct messaging with patients.",
  },
  EMPLOYEE_SETTINGS: {
    label: "Employee Management",
    description: "Add and manage employee details including compensation, timesheets and documents.",
  },
  CLAIMS: {
    label: "Claims",
    description: "Access patient pre-auths, claims and EOBs (including status and fee details).",
  },
  REPORTING: {
    label: "Reporting",
    description:
      "Access practice level reporting on financial, operational, hygiene and administrative areas.",
  },
  BILLING: {
    label: "Billing & Payments",
    description: "Access Ledger, Invoices, Payments, Statements and Patient Wallet.",
  },
  PRACTICE_SETTINGS: {
    label: "Practice Settings",
    description: "Access settings for configuring your practice, schedule, charting, forms and more.",
  },
};

export const PERMISSIONS_DATA: Record<
  PermissionActionVO["action"],
  { label: string; tooltip?: string; withTimeLimit: boolean }
> = {
  // COMMUNICATION
  BULK_MESSAGE: {
    label: "Messaging Power Tools",
    tooltip: "Bulk message patients, create campaigns & schedule automated messages.",
    withTimeLimit: false,
  },
  BULK_MAIL_MESSAGE: {
    label: "Mail Statements (Additional Costs Apply)",
    withTimeLimit: false,
  },

  // EMPLOYEE_SETTINGS
  EDIT_ROLE: {
    label: "Edit Roles",
    tooltip: "Change or add permissions for an employee role.",
    withTimeLimit: false,
  },
  RUN_PAYROLL: {
    label: "Run Payroll",
    tooltip: "Ability to process and manage payroll.",
    withTimeLimit: false,
  },

  // REPORTING
  ACCESS_FINANCIAL: {
    label: "Financial Reporting",
    tooltip:
      "Access financial reporting such as production, collection, outstanding collections, adjustments, ledger entries and patient wallet balances.",
    withTimeLimit: false,
  },
  ACCESS_OPERATIONAL: {
    label: "Operational Reporting",
    tooltip:
      "Access operational reporting such as patients treated, exams, appointments completed, unscheduled treatment and referral sources.",
    withTimeLimit: false,
  },
  ACCESS_HYGIENE: {
    label: "Hygiene Reporting",
    tooltip: "Access hygiene reporting such as appointments completed and unscheduled recalls.",
    withTimeLimit: false,
  },
  ACCESS_ADMINISTRATIVE: {
    label: "Administrative Reporting",
    tooltip: "Access administrative reports such as employee hours worked.",
    withTimeLimit: false,
  },
  PRINT_DOWNLOAD: {
    label: "Print & Download Reports",
    tooltip: "Ability to print or download a csv of the report.",
    withTimeLimit: false,
  },

  // BILLING
  COLLECT_MANAGE: {
    label: "Collect payment/pre-payment and manage payment methods",
    tooltip: undefined,
    withTimeLimit: false,
  },
  EDIT_PAYMENT: {
    label: "Edit Payments",
    tooltip: undefined,
    withTimeLimit: true,
  },
  DELETE_PAYMENT: {
    label: "Delete Payments",
    tooltip: undefined,
    withTimeLimit: true,
  },
  CREATE_REFUND: {
    label: "Refund",
    tooltip: undefined,
    withTimeLimit: false,
  },
  ADD_ADJUSTMENT_DISCOUNT: {
    label: "Add Discount Adjustment",
    tooltip: undefined,
    withTimeLimit: false,
  },
  ADD_ADJUSTMENT_CHARGE: {
    label: "Add Surcharge Adjustment",
    tooltip: undefined,
    withTimeLimit: false,
  },

  // PRACTICE_SETTINGS
  ACCESS_PRACTICE: {
    label: "Practice Setup",
    tooltip: "Practice details such as legal company name, tax ID, address, etc.",
    withTimeLimit: false,
  },
  ACCESS_PAYROLL: {
    label: "Payroll Setup",
    tooltip: "Payroll information and access to the Gusto platform.",
    withTimeLimit: false,
  },
  ACCESS_SCHEDULING: {
    label: "Scheduling",
    tooltip:
      "Office hours, provider hours, rooms, online booking, appointment categories, appointment & patient icons and appointment card design.",
    withTimeLimit: false,
  },
  ACCESS_INSURANCE: {
    label: "Insurance",
    tooltip: "Fee schedules, insurance carriers, insurance plans and default limitations.",
    withTimeLimit: false,
  },
  ACCESS_CHARTING: {
    label: "Clinical",
    tooltip: "Quick select charting options, procedure codes and clinical notes.",
    withTimeLimit: false,
  },
  ACCESS_LAB_CASES: {
    label: "Lab Cases",
    tooltip: "Labs, lab case types and lab case returns reasons.",
    withTimeLimit: false,
  },
  ACCESS_CLAIMS: {
    label: "Claims",
    tooltip:
      "Practice information attached to claims, release of patient information and patient assignment of benefits.",
    withTimeLimit: false,
  },
  ACCESS_PAYMENTS: {
    label: "Payments",
    tooltip: "Bank account details, payment terminals, patient payment settings and custom adjustments.",
    withTimeLimit: false,
  },
  ACCESS_COMMS: {
    label: "Communications",
    tooltip: "E-mail settings.",
    withTimeLimit: false,
  },
  ACCESS_FORMS: {
    label: "Patient",
    tooltip: "Alerts, folders, forms, referral options, kiosk mode, and tags.",
    withTimeLimit: false,
  },
  ACCESS_SECURITY: {
    label: "Security",
    tooltip: "IP Authorization and multi-factor authentication.",
    withTimeLimit: false,
  },
};

export const BULK_MAIL_MESSAGE_PERMISSION_ACTION_UUID = "40d0b818-0f8c-4916-80af-26a2b9cab778";
