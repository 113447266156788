import {
  Enum,
  ParsedParams,
  NumCSV,
  NumVal,
  BoolVal,
  StrVal,
  withDefault,
  routesConfigHelper,
} from "@libs/router/url";
import { DailyHuddleAppointmentVO } from "@libs/api/generated-api";
import { patientPanelQueryConfig } from "utils/routing/patientPanel";
import { DateRangeVal } from "utils/routing/dateRange";

export type AppointmentGrouping = "room" | "provider";

export const ScheduleQueryParams = {
  date: StrVal,
  groupAppointmentsBy: Enum<AppointmentGrouping>().Val(["provider", "room"]),
  showHolds: BoolVal,
  ...patientPanelQueryConfig,
};

const EditAppointmentOverridesQueryParams = {
  roomId: withDefault(NumVal, 0),
  hygienistId: withDefault(NumVal, 0),
  dentistId: withDefault(NumVal, 0),
  date: withDefault(StrVal, ""),
  startTime: withDefault(StrVal, ""),
};

export const EditAppointmentOverrideKeys = Object.keys(
  EditAppointmentOverridesQueryParams
) as (keyof typeof EditAppointmentOverridesQueryParams)[];

export const EditAppointmentQueryParams = {
  ...EditAppointmentOverridesQueryParams,
  from: StrVal,
};

export const ScheduleBlockQueryParams = {
  roomId: withDefault(NumVal, 0),
  hygienistId: withDefault(NumVal, 0),
  dentistId: withDefault(NumVal, 0),
  from: StrVal,
  duration: NumVal,
};

const SortEnum = Enum<"ASC" | "DESC">().Val(["ASC", "DESC"]);

export type DateQuickFilter = "YESTERDAY" | "TODAY" | "TOMORROW" | "THIS_WEEK" | "NEXT_WEEK";

const DailyHuddleQueryParams = {
  dateRange: DateRangeVal,
  tagIds: NumCSV,
  patientName: StrVal,
  providerIds: NumCSV,
  roomIds: NumCSV,
  quickDateFilter: Enum<DateQuickFilter>().Val(["YESTERDAY", "TODAY", "TOMORROW", "THIS_WEEK", "NEXT_WEEK"]),
  statuses: Enum<Exclude<DailyHuddleAppointmentVO["appointmentState"], "_DELETED" | "UNSCHEDULED">>().CSV([
    "UNCONFIRMED",
    "CONFIRMED",
    "CANCELED",
    "READY",
    "CHECKOUT",
    "NO_SHOW",
    "ARRIVED",
    "IN_PROGRESS",
    "COMPLETED",
    "REQUESTED",
  ]),
  clinicalNoteStatuses: Enum<NonNullable<DailyHuddleAppointmentVO["clinicalNoteStatus"]>>().CSV([
    "INCOMPLETE",
    "COMPLETED",
    "DRAFT",
  ]),
  patientNotes: StrVal,
  ...patientPanelQueryConfig,
};

export type DailyHuddleQuery = ParsedParams<typeof DailyHuddleQueryParams>;
export type DailyHuddleQueryUpdates = Partial<DailyHuddleQuery>;

export const SchedulingRoutesConfig = routesConfigHelper({
  schedule: {
    path: "/schedule",
    query: ScheduleQueryParams,
  },
  addPatientAppointment: {
    path: "/schedule/add-patient-appointment",
    query: {
      patientProcedureIds: NumCSV,
      from: StrVal,
      findFromDate: StrVal,
      ...patientPanelQueryConfig,
    },
  },
  scheduleAppointment: {
    path: "/schedule/schedule-appointment/:date/:startTime",
    query: {
      ...ScheduleBlockQueryParams,
      ...patientPanelQueryConfig,
    },
    params: {
      date: StrVal,
      startTime: StrVal,
    },
  },
  scheduleAsap: {
    path: "/schedule/asap/add",
    query: {
      from: StrVal,
      patientId: NumVal,
    },
  },
  scheduleBlock: {
    path: "/schedule/schedule-block/:date/:startTime",
    query: ScheduleBlockQueryParams,
    params: {
      date: StrVal,
      startTime: StrVal,
    },
  },
  editAppointment: {
    path: "/schedule/appointments/:appointmentId/:patientId/edit",
    query: EditAppointmentQueryParams,
    params: {
      appointmentId: NumVal,
      patientId: NumVal,
    },
  },
  editBlock: {
    path: "/schedule/blocks/:blockId/edit/:instanceDate",
    query: {
      from: StrVal,
    },
    params: {
      blockId: NumVal,
      instanceDate: StrVal,
    },
  },
  scheduleAsapList: {
    path: "/schedule/asap",
    query: {
      from: StrVal,
      sortDirection: withDefault(SortEnum, "ASC"),
      sortField: withDefault(Enum<"date" | "duration">().Val(["date", "duration"]), "date"),
      ...patientPanelQueryConfig,
    },
  },
  scheduleRequestsList: {
    path: "/schedule/requests",
    query: {
      from: StrVal,
      sortDirection: withDefault(SortEnum, "ASC"),
      sortField: withDefault(Enum<"date">().Val(["date"]), "date"),
      ...patientPanelQueryConfig,
    },
  },
  huddle: {
    path: "/schedule/huddle",
    query: DailyHuddleQueryParams,
  },
});
