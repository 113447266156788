import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { paths } from "utils/routing/paths";
import { useOnboardingEmployee } from "components/Onboarding/useOnboardingEmployee";

// If user finds themselves in employee onboarding when they have already been onboarded
// as an employee, they get redirected back to practice onboarding
export const EmployeeOnboardingGuard: FC<PropsWithChildren> = ({ children }) => {
  const { employmentDetails } = useOnboardingEmployee();

  return employmentDetails.status === "PENDING" ? (
    children
  ) : (
    <Navigate replace to={paths.onboarding({ step: "practice-owner" })} />
  );
};
