import { stripAllButNumbers } from "@libs/utils/inputFormatting";

export const PHONE_NUMBER_LENGTH = 10;

/**
 * Returns a US phone number string in the format `(XXX) XXX-XXXX`
 * @param phoneNumberString a string of numbers representing a full or partial phone number
 * @returns
 */
export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replaceAll(/\D/g, "");
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";

    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

const AFTER_AREA_CODE = 3;
const BEFORE_LAST_SECTION = 6;

/**
 * Formats a string of phone number digits (full (10) or partial) to a US phone
 * number (XXX) XXX-XXXX
 *
 * @param value a string of 10 digits
 * @returns a formatted phone number in US format
 */
export const phoneNumberFormatter = (value?: string) => {
  if (!value) {
    return "";
  }

  const characters = [...value];
  let formatted = "(";

  for (const [index, char] of characters.entries()) {
    if (index === AFTER_AREA_CODE) {
      formatted += `) ${char}`;
    } else if (index === BEFORE_LAST_SECTION) {
      formatted += `-${char}`;
    } else {
      formatted += char;
    }
  }

  return formatted;
};

export const formattedCharacters = ["(", ")", " ", "-"];

export const parsePhoneNumber = (value: string) => stripAllButNumbers(value).slice(0, PHONE_NUMBER_LENGTH);
