import { Navigate } from "react-router-dom";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";

export const ForwardToInfoStep = () => {
  const { patientId, insuranceId } = usePathParams("patientInsuranceDetails");
  const {
    query: { from },
  } = useQueryParams("patientInsuranceDetails");

  return (
    <Navigate
      replace
      to={paths.patientInsuranceStep(
        {
          step: "info",
          patientId,
          insuranceId,
        },
        from ? { from } : undefined
      )}
    />
  );
};
