import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { paths } from "utils/routing/paths";
import { isSupportIdentityToken } from "utils/auth";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";

export const loader =
  ({ storage }: PracticeRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);
    const returnUrl = getFullUrl(url);

    if (activityStorage.isRecentlyActive()) {
      if (!tokens.identity) {
        return redirectDocument(paths.signIn({ returnUrl }));
      }

      if (!tokens.account) {
        return redirectDocument(paths.selectAccount({ returnUrl }));
      }

      return {
        accountToken: tokens.account,
        email: tokens.identity.email,
        isSupportUser: isSupportIdentityToken(tokens.identity),
      };
    } else if (tokens.identity) {
      return redirect(paths.signOut({ returnUrl }));
    }

    return redirectDocument(paths.signIn({ returnUrl }));
  };

export type SignedInAppLoader = typeof loader;
