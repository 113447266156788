import { SECOND_IN_MS } from "@libs/utils/date";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { AccountToken } from "@libs/storage/types";

const version = "v1";

export const getAccountTokenStorage = (storage: Storage) => {
  const accountTokenStorage = wrapStorage<AccountToken, "accountToken">("accountToken", storage);

  return {
    getAccountToken: (email: string) => {
      return accountTokenStorage.get(email, version);
    },
    setAccountToken: (email: string, accountToken: AccountToken) => {
      return accountTokenStorage.set(email, accountToken, {
        version,
        expires: accountToken.expiresAt * SECOND_IN_MS - Date.now(),
      });
    },
    clearAccountToken: (email: string) => {
      return accountTokenStorage.clear(email);
    },
  };
};
