import React, { FC, PropsWithChildren, ReactNode } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export interface ActionModalProps {
  disableClose?: boolean;
  displaySecondaryButton?: boolean;
  handleCancel?: () => void;
  handleClose: () => void;
  handleSave: () => void;
  isLoading?: boolean;
  primaryButtonDisabled?: boolean;
  primaryButtonDisabledTooltip?: string;
  primaryButtonText?: string;
  secondaryButtonDisabled?: boolean;
  secondaryButtonText?: string;
  secondaryButtonDisabledTooltip?: string;
  secondaryTitle?: string;
  size?: ModalProps["size"];
  title?: string;
}

export const ActionModalTitle: FC<{
  title: ReactNode;
  secondaryTitle?: ReactNode;
}> = ({ title, secondaryTitle }) => (
  <div className="flex items-center">
    <div className="font-sansSemiBold">{title}</div>
    {secondaryTitle ? (
      <>
        <div className="bg-greyDark w-px h-3 mx-3" />
        <div className="text-sm font-sans">{secondaryTitle}</div>
      </>
    ) : null}
  </div>
);

export const ActionModal: React.FC<PropsWithChildren<ActionModalProps>> = ({
  children,
  disableClose = false,
  displaySecondaryButton = true,
  handleCancel,
  handleClose,
  handleSave,
  isLoading = false,
  primaryButtonDisabled,
  primaryButtonDisabledTooltip,
  primaryButtonText,
  secondaryButtonDisabled,
  secondaryButtonText,
  secondaryButtonDisabledTooltip,
  secondaryTitle,
  size,
  title,
}) => {
  return (
    <Modal
      size={size}
      onClose={disableClose ? undefined : handleClose}
      title={title ? <ActionModalTitle title={title} secondaryTitle={secondaryTitle} /> : null}
    >
      <ModalContent className="pt-5 pb-12 px-8 text-greyDark dark:text-white">{children}</ModalContent>
      <ModalFooter>
        {displaySecondaryButton && (
          <FloatingTooltip content={secondaryButtonDisabledTooltip} theme="SMALL">
            <div>
              <Button
                disabled={secondaryButtonDisabled}
                onClick={handleCancel || handleClose}
                theme="secondary"
                className="min-w-button"
              >
                {secondaryButtonText || "Cancel"}
              </Button>
            </div>
          </FloatingTooltip>
        )}
        <FloatingTooltip content={primaryButtonDisabledTooltip} theme="SMALL">
          <div>
            <AsyncButton
              className="min-w-button"
              disabled={primaryButtonDisabled}
              isLoading={isLoading}
              onClick={handleSave}
            >
              {primaryButtonText || "Done"}
            </AsyncButton>
          </div>
        </FloatingTooltip>
      </ModalFooter>
    </Modal>
  );
};
