import { ApiMutationMethodData, GetMutationApiMethod } from "@libs/@types/api";
import type { MakeMutation, MakeMutationQueue } from "@libs/@types/apiMutations";
import { getQueryKey } from "@libs/utils/queries";

export const makeMutation: MakeMutation =
  (options) =>
  ({ api, queryClient }) => [
    [getQueryKey(...options.mutationKey)],
    (args) => {
      const [domain, apiCall] = options.mutationKey;
      const method = api[domain][apiCall] as GetMutationApiMethod<typeof domain, typeof apiCall>;

      return method(...options.formatParams(args)) as Promise<
        ApiMutationMethodData<typeof domain, typeof apiCall>
      >;
    },
    options.mutationOptions?.(queryClient),
  ];

export const makeMutationQueue: MakeMutationQueue =
  (options) =>
  ({ api, queryClient }) => [
    [getQueryKey(...options.mutationKey)],
    (args) => {
      const [domain, apiCall] = options.mutationKey;
      const method = api[domain][apiCall] as GetMutationApiMethod<typeof domain, typeof apiCall>;

      return method(...options.formatParams(args)) as Promise<
        ApiMutationMethodData<typeof domain, typeof apiCall>
      >;
    },
    options.mutationOptions?.(queryClient),
  ];
