import { StrVal, routesConfigHelper } from "@libs/router/url";

const SensorParams = {
  deviceId: StrVal,
};
const SensorQueryParams = {
  from: StrVal,
};

export const ImagingSensorsRoutesConfig = routesConfigHelper({
  sensors: {
    path: "/sensors/:deviceId",
    params: SensorParams,
    query: SensorQueryParams,
  },
});
