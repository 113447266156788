import { useTypedPathParams } from "@libs/hooks/useTypedPathParams";
import { ParsedPathParams, RoutesConfigsWithPathParams } from "@libs/router/url";
import { RoutesConfig, routesConfig } from "utils/routing/paths";

// Best if used in a place that is rendered on multiple routes
export function useNullablePathParams<P extends RoutesConfigsWithPathParams<RoutesConfig>>(routeName: P) {
  const routeConfig = routesConfig[routeName];

  return useTypedPathParams(routeConfig, { nullable: true }) as ParsedPathParams<
    NonNullable<RoutesConfig[P]["params"]>
  > | null;
}

// Will throw an error if used on the wrong path
export function usePathParams<P extends RoutesConfigsWithPathParams<RoutesConfig>>(routeName: P) {
  const routeConfig = routesConfig[routeName];

  return useTypedPathParams(routeConfig) as ParsedPathParams<NonNullable<RoutesConfig[P]["params"]>>;
}
