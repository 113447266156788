import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";

export const FormSectionTitle: FC<{
  children?: ReactNode;
  subTitle?: ReactNode;
  useCustomStyling?: boolean;
  disabled?: boolean;
  required?: boolean;
}> = ({ children, subTitle, useCustomStyling = false, disabled = false, required = false }) => {
  return (
    <div className={cx(!useCustomStyling && "font-sansSemiBold text-sm mb-2", disabled && "text-slate-500")}>
      {children}
      {!disabled && required && <RequiredAsterisk />}
      {subTitle && (
        <div className={cx("text-xs/4 font-sans mt-1", disabled ? "text-slate-500" : "text-slate-700")}>
          {subTitle}
        </div>
      )}
    </div>
  );
};
