import { CognitoErrorCode } from "@libs/utils/cognito";
import { getIdentityToken } from "@libs/auth/getTokens";

const cognitoErrorMessages: Partial<Record<CognitoErrorCode, string>> = {
  NotAuthorizedException: "Incorrect email or password.",
  UserNotFoundException: "Incorrect email or password.",
  InvalidPasswordException: "Password did not conform with policy.",
  CodeMismatchException: "Invalid verification code provided, please try again.",
  LimitExceededException: "Attempt limit exceeded, please try after some time.",
  Unknown: "Sorry, something went wrong.",
  ExpiredCodeException: "Please request or wait for a new code. This one is expired.",
};

export const getCognitoErrorMessage = (code?: CognitoErrorCode | null) => {
  if (code && code in cognitoErrorMessages) {
    return cognitoErrorMessages[code] ?? "";
  }

  return "";
};

export const isSupportIdentityToken = (identityToken: Awaited<ReturnType<typeof getIdentityToken>>) => {
  return identityToken.username.startsWith("archy-support");
};
