import { FC } from "react";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { paths } from "utils/routing/paths";

export const RecoverError: FC = () => {
  return (
    <div
      className={`
        flex
        flex-col
        h-full
        items-center
        justify-center
        min-h-[inherit]
      `}
    >
      <div className="flex items-center justify-center gap-x-2 mb-4 p-2">
        <ErrorIcon className="text-red-500 w-6 h-6" />
        <p className="text-base">Sorry, something went wrong.</p>
      </div>
      <div className="flex gap-x-3">
        <Button onClick={() => window.location.reload()}>Reload</Button>
        <ButtonLink theme="secondary" href={paths.signOut()}>
          Sign Out
        </ButtonLink>
      </div>
    </div>
  );
};
