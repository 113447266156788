export class MemoryStorage implements Storage {
  private _data = new Map<string, string>();

  clear(): void {
    this._data.clear();
  }

  getItem(key: string): string | null {
    return this._data.get(key) ?? null;
  }

  removeItem(key: string): void {
    this._data.delete(key);
  }

  key(index: number): string | null {
    return [...this._data.keys()][index] ?? null;
  }

  setItem(key: string, value: string): void {
    this._data.set(key, value);
  }

  get length(): number {
    return this._data.size;
  }
}

function hasLocalStorage() {
  try {
    const storage = window.localStorage;
    const featureTestKey = "gfLocalStorageFeatureTest";

    storage.setItem(featureTestKey, "1");
    storage.removeItem(featureTestKey);

    return true;
  } catch {
    return false;
  }
}

function hasSessionStorage() {
  try {
    const storage = window.sessionStorage;
    const featureTestKey = "gfStorageFeatureTest";

    storage.setItem(featureTestKey, "1");
    storage.removeItem(featureTestKey);

    return true;
  } catch {
    return false;
  }
}

export const getLocalStorage = () => {
  const hasLS = hasLocalStorage();

  return hasLS ? window.localStorage : getMemoryStorage();
};

export const getSessionStorage = () => {
  const hasSS = hasSessionStorage();

  return hasSS ? window.sessionStorage : getMemoryStorage();
};

export const getMemoryStorage = () => {
  return new MemoryStorage();
};
