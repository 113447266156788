import { AutomationJourneyVO } from "@libs/api/generated-api";
import { PAGE_SIZE } from "@libs/utils/constants";
import { Enum, NumVal, StrVal, routesConfigHelper, withDefault } from "@libs/router/url";

const AutomationPathParams = {
  journeyType: Enum<AutomationJourneyVO["type"]>().Val([
    "POST_APPOINTMENT",
    "APPOINTMENT",
    "RECALL",
    "FORMS",
  ]),
};

const CampaignPathParams = {
  campaignUuid: StrVal,
};

const DefaultQuery = {
  from: StrVal,
};

const CampaignQuery = {
  ...DefaultQuery,
  previewUrl: StrVal,
};

export const CommunicationsRoutesConfig = routesConfigHelper({
  communications: {
    path: "/communications",
    query: { page: withDefault(NumVal, 1), pageSize: withDefault(NumVal, PAGE_SIZE) },
  },
  journey: {
    path: "/communications/automations/:journeyType",
    params: AutomationPathParams,
    query: DefaultQuery,
  },
  createAutomationAction: {
    path: "/communications/automations/:journeyType/actions/create",
    params: AutomationPathParams,
    query: DefaultQuery,
  },
  editAutomationAction: {
    path: "/communications/automations/:journeyType/actions/:actionUuid",
    params: {
      ...AutomationPathParams,
      actionUuid: StrVal,
    },
    query: DefaultQuery,
  },
  campaign: {
    path: "/communications/campaigns/:campaignUuid",
    params: CampaignPathParams,
    query: CampaignQuery,
  },
  campaignEmail: {
    path: "/communications/campaigns/:campaignUuid/email",
    params: CampaignPathParams,
    query: DefaultQuery,
  },
  campaignTemplates: {
    path: "/communications/campaigns/:campaignUuid/templates",
    params: CampaignPathParams,
    query: DefaultQuery,
  },
  campaignGroup: {
    path: "/communications/campaigns/:campaignUuid/group",
    params: CampaignPathParams,
    query: DefaultQuery,
  },
});
