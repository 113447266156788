import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import designConfig from "@libs/design.config";

// For only very spooky circumstances (like when you're in a dark room)
export const DarkRoomSkeleton: React.FC<Omit<SkeletonProps, "baseColor" | "highlightColor">> = (props) => {
  const baseColor = designConfig.colors.greyDark;
  const highlight = designConfig.colors.greyMedium;

  return <Skeleton baseColor={baseColor} highlightColor={highlight} {...props} />;
};
