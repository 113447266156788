import type { ApiErrorResponse } from "@libs/@types/api";
import { hasErrorOnResponse } from "@libs/utils/hasErrorOnResponse";

/**
 * Use this in a catch statement to convert the error to a typed HttpResponse.
 * @param error The error to check.
 * @returns True if its an HttpResponse error with error JSON parsed, otherwise false.
 */
export const isHttpResponseError = (error: unknown): error is ApiErrorResponse => {
  const typedError = error as ApiErrorResponse;

  return hasErrorOnResponse(error) && !(typedError.error instanceof Error);
};
