import { useCallback, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isOneOf } from "@libs/utils/isOneOf";

import {
  upsertQueryParams,
  formUrl,
  ParsedParams,
  queryParamsToString,
  parseQueryParams,
  QueryResultsMap,
  AnyQueryConfig,
} from "@libs/router/url";

type UpdateType = "pushIn" | "push" | "replaceIn" | "replace";

export function useQueryParamsConfig<QC extends AnyQueryConfig>(queryConfig: QC) {
  const navigate = useNavigate();
  const location = useLocation();
  const lastQueryResults = useRef<QueryResultsMap<QC>>({});

  const urlSearchParams = useMemo(() => {
    if (location.search) {
      return new URLSearchParams(location.search);
    }

    return null;
  }, [location.search]);

  const getUrl = useCallback(
    (keyValues: Partial<ParsedParams<NonNullable<QC>>>, update?: boolean) => {
      const updatedKeyValues = queryParamsToString(queryConfig, keyValues);

      return update
        ? formUrl(location.pathname, upsertQueryParams(updatedKeyValues, urlSearchParams))
        : formUrl(location.pathname, upsertQueryParams(updatedKeyValues));
    },
    [urlSearchParams, location.pathname, queryConfig]
  );

  const updateQuery = useCallback(
    (updateType: UpdateType, keyValues: Partial<ParsedParams<NonNullable<QC>>>) => {
      const url = getUrl(keyValues, isOneOf(updateType, ["pushIn", "replaceIn"]));

      if (isOneOf(updateType, ["push", "pushIn"])) {
        navigate(url);
      } else {
        navigate(url, { replace: true });
      }
    },
    [navigate, getUrl]
  );

  const query = useMemo(() => {
    const { parsed, resultsMap } = parseQueryParams(
      queryConfig,
      urlSearchParams,
      lastQueryResults.current
    ) as {
      parsed: ParsedParams<NonNullable<QC>>;
      resultsMap: QueryResultsMap<QC>;
    };

    lastQueryResults.current = resultsMap;

    return parsed;
  }, [urlSearchParams, queryConfig]);

  return {
    getUrl,
    updateQuery,
    query,
  };
}
