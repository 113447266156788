import React from "react";

/**
 * @deprecated
 * This hook shouldn't be used in new code. Instead create a subcomponent that doesn't
 * render until `data` is available. In the component define a useState hook that
 * can receive the data. It achieves the same result and is more idiomatic React.
 *
 * An effect to run once, and only once, when data has been loaded.
 * @param onLoad Function called only once after `data` becomes truthy.
 * @param data The `data` passed to `onLoad` when it becomes available.
 */
export const useSyncOnce = <T = unknown>(onLoad: (data: T) => void, data?: T) => {
  const hasLoaded = React.useRef(false);

  React.useEffect(() => {
    if (!hasLoaded.current && data) {
      onLoad(data);
      hasLoaded.current = true;
    }
  }, [data, onLoad]);
};
